import React, { Fragment, useContext, useEffect, useState } from 'react'
import CertificateCard from '../../components/CertificateCard'
import EducationCard from '../../components/EducationCard'
import JobCard from '../../components/JobCard'
import LoadingState from '../../components/LoadingState'
import WorkHistoryCard from '../../components/WorkHistoryCard'
import { AuthContext } from '../../context/AuthContext'
import API from '../../services/API'
import JobAPI from '../../services/JobAPI'
import queryString from 'query-string'
import gravatar from 'gravatar'
import { toast } from 'react-toastify'

const ApplicantProfile = ({ match, location }) => {
	const authContext = useContext(AuthContext)

	const [userProfile, setUserProfile] = useState({ data: null, isLoading: true })
	const [checker, setChechker] = useState({ data: null, isLoading: true })
	const [coverError, setCoverError] = useState(false)
	const [avatarError, setAvatarError] = useState(false)

	const [submitting, setSubmitting] = useState(false)
	const [error, setError] = useState(false)

	const onChangeAplikan = async (statusType, id) => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await JobAPI.changeStatusApplikan({ status: statusType }, id)
				setSubmitting(false)
				toast.success('Sukses')
				getUserProfile()
				checkIsApplied()
			} catch (error) {
				console.log(error)
				toast.error('Gagal')

				setSubmitting(false)
			}
		}
	}

	const getUserProfile = async () => {
		try {
			const response = await API.userProfile(match.params.id)
			setUserProfile({
				isLoading: false,
				data: response.data,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const checkIsApplied = async () => {
		const jobID = queryString.parse(location.search).jobID
		if (jobID) {
			try {
				const response = await JobAPI.checkIsApplied({ jobID: jobID, jobSeekerID: match.params.id })
				setChechker({
					data: response.data,
					isLoading: false,
				})
			} catch (error) {
				console.log(error)
			}
		}
	}

	console.log(checker)

	useEffect(() => {
		getUserProfile()
		checkIsApplied()
	}, [])
	return (
		<div className='container mx-auto'>
			{userProfile.isLoading ? (
				<LoadingState />
			) : (
				<Fragment>
					<div className='mt-12 flex flex-col md:flex-row'>
						<div className='w-full flex-1 lg:mr-2 px-4 md:px-0'>
							<div className='bg-white rounded-md border'>
								<div className='h-32 md:h-48 overflow-hidden object-center mx-auto relative'>
									<img
										onError={() => setCoverError(true)}
										src={
											coverError
												? 'https://picsum.photos/920/192'
												: userProfile.data.jobSeekerAvatar?.cover ?? 'https://picsum.photos/920/192'
										}
										alt='Jiso'
										className='w-full h-full object-cover rounded-t-md'
									/>
								</div>

								<div className='px-6 text-gray-800 pb-8 relative'>
									<div className='-mt-20 flex justify-between items-center px-4'>
										<div className='flex-1'>
											<img
												className='h-32 w-32 md:h-48 md:w-48 object-cover rounded-full border p-1 bg-white mx-auto'
												onError={() => setAvatarError(true)}
												src={
													avatarError
														? gravatar.url(userProfile.data.email, { s: 200, default: 'identicon' })
														: userProfile.data.jobSeekerAvatar?.avatar ??
														  gravatar.url(userProfile.data.email, { s: 200, default: 'identicon' })
												}
												alt='Profile'
											/>
										</div>
									</div>

									<div className='text-center mt-2 mb-8'>
										<h1 className='text-xl font-bold'>{userProfile.data.name}</h1>

										<h2>{userProfile.city ? userProfile.city.name : ''}</h2>
										<h2 className='font-medium mt-4'>Teknik Komputer dan Jaringan</h2>
										<h1 className='font-light'>SMKN 4 Batam</h1>
									</div>

									{userProfile.data.about && (
										<Fragment>
											<hr />
											<div className='text-center mt-8 lg:px-16'>
												<h1 className='text-xl font-bold mb-2 italic'>Tentang</h1>
												<p className='font-light text-base'>
													<i className='icofont-quote-left'></i> &nbsp; {userProfile.data.about}
												</p>
											</div>
										</Fragment>
									)}
								</div>
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Skills</h1>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								<div className='w-full flex flex-wrap'>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>Javascript</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>HTML</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>CSS</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>Web Development</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>SCSS</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>React JS</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>Node JS</span>
								</div>
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Pengalaman Kerja</h1>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								{/* <div className='grid gap-4 md:grid-cols-2'>
									<WorkHistoryCard />
								</div> */}

								{userProfile.data.jobSeekerExperiences.length > 0 ? (
									<div className='grid gap-4 md:grid-cols-2'>
										{userProfile.data.jobSeekerExperiences.map(exp => (
											<WorkHistoryCard experience={exp} />
										))}
									</div>
								) : (
									<div className='w-full'>
										<div className='mx-auto text-center'>
											<img
												src={require('../../assets/images/empty.png')}
												className='h-32 mx-auto'
												alt='Empty Education'
											/>
											<p>Belum ada data...</p>
										</div>
									</div>
								)}
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Pendidikan</h1>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								{userProfile.data.jobSeekerEducations.length > 0 ? (
									<div className='grid gap-4 md:grid-cols-2'>
										{userProfile.data.jobSeekerEducations.map(education => (
											<EducationCard key={education.ID} education={education} />
										))}
									</div>
								) : (
									<div className='w-full'>
										<div className='mx-auto text-center'>
											<img
												src={require('../../assets/images/empty.png')}
												className='h-32 mx-auto'
												alt='Empty Education'
											/>
											<p>Belum ada data...</p>
										</div>
									</div>
								)}
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Lisensi dan Sertifikasi</h1>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>
								{userProfile.data.jobSeekerLicenseCertifications.length > 0 ? (
									<div className='grid gap-4 md:grid-cols-2'>
										{userProfile.data.jobSeekerLicenseCertifications.map((cert, index) => (
											<CertificateCard key={index} certificate={cert} showEdit={false} />
										))}
									</div>
								) : (
									<div className='w-full'>
										<div className='mx-auto text-center'>
											<img
												src={require('../../assets/images/empty.png')}
												className='h-32 mx-auto'
												alt='Empty Education'
											/>
											<p>Belum ada data...</p>
										</div>
									</div>
								)}
							</div>
						</div>

						{/* Right side */}
						<div className='bg-transparent w-full md:overflow-auto md:w-1/4 md:ml-2 min-h-0 text-gray-800 pb-8 px-4 mt-4 md:px-0 md:mt-0'>
							<div className='bg-white p-4 border rounded-md'>
								<h1 className='text-base font-bold mb-4'>Action</h1>
								<div className='w-20 h-px bg-indigo-500 mt-2 mb-4'></div>
								{checker.isLoading ? (
									<div className='w-full text-center text-sm'>
										<img
											src={require('../../assets/images/empty_data.png')}
											className='h-32 w-32 mx-auto'
											alt='Empty'
										/>
									</div>
								) : checker.data.jobApplicant.jobApplicantConfirmed !== null ? (
									<Fragment>
										<div className='grid gap-3'>
											<a
												href={`mailto:${checker.data.jobApplicant.jobSeeker.email}`}
												className={
													'focus:outline-none rounded-sm px-3 py-1 hover:shadow-none transition duration-100 bg-green-400 text-center'
												}>
												Email
											</a>
										</div>
									</Fragment>
								) : checker.data.jobApplicant.jobApplicantConfirmed === null &&
								  checker.data.jobApplicant.jobApplicantRejected === null ? (
									<Fragment>
										<div className='grid gap-3'>
											<button
												onClick={() => onChangeAplikan('confirmed', match.params.id)}
												className={
													'focus:outline-none rounded-sm px-3 py-1 hover:shadow-none transition duration-100 bg-green-400'
												}>
												Terima
											</button>

											<button
												onClick={() => onChangeAplikan('rejected', match.params.id)}
												className={
													'focus:outline-none rounded-sm px-3 py-1 hover:shadow-none transition duration-100 bg-red-300'
												}>
												Tolak
											</button>
										</div>
									</Fragment>
								) : null}
							</div>
						</div>
					</div>

					<br />
					<br />
				</Fragment>
			)}
		</div>
	)
}

export default ApplicantProfile
