import Axios from '../utils/axios'
import queryString from 'query-string'

export default class API {
	static loginUser(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post('/access/login', formData, {
					headers: {
						Role: 'JobSeeker',
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static loginCompany(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post('/access/login', formData, {
					headers: {
						Role: 'Company',
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static checkNIK(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.post(`/mitra-vokasi/job-seeker/sample/${id}`, {
					headers: {
						Role: 'JobSeeker',
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static checkMOU(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.post(`/mitra-vokasi/company/sample/${id}`, {
					headers: {
						Role: 'Company',
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static checkAccount() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.post('/access/account')

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static userProfile(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`mitra-vokasi/job-seeker/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static register(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post('/mitra-vokasi/job-seeker/register', formData, {
					headers: {
						Role: 'JobSeeker',
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateJobSeeker(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/mitra-vokasi/job-seeker/update', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static changePassword(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/access/change/password', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
