import React, { useState, useContext, useEffect } from 'react'
import ReactModal from 'react-modal'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { DataContext } from '../../../context/DataContext'
import JobAPI from '../../../services/JobAPI'
import _ from 'lodash'
import LocationPicker from 'react-location-picker'
import Geocode from 'react-geocode'

import 'react-quill/dist/quill.snow.css'
import MasterAPI from '../../../services/MasterAPI'

const EditCompanyModal = ({
	visible,
	onCancel,
	mode = 'add',
	onSucces = () => null,
	companyEdit = null,
	setCompanyEdit,
}) => {
	const dataContext = useContext(DataContext)

	const { defaultJobFunction, jobType, companySize } = dataContext

	const [submitting, setSubmitting] = useState(false)

	const [formValue, setFormValue] = useState({
		jobFunctionID: '',
		jobTypeID: '',
		experienceMin: '',
		experienceMax: '',
		vacancies: '',
		about: '',
		salaryMin: '',
		salaryMax: '',
		expired: '',
		title: '',
		email: '',
	})
	const [skillsForm, setSkillsForm] = useState([])

	const onSubmit = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)

			try {
				const response = await MasterAPI.updateCompany(companyEdit, companyEdit?.ID)
				console.log(response.data)
				// if (response.data.ID) {
				// 	await JobAPI.assignSkills(skillsForm, response.data.ID)
				// }
				onSucces()
				setSubmitting(false)
				onCancel()
			} catch (error) {
				console.log(error)
				setSubmitting(false)
			}
		}
	}

	const [position, setPosition] = useState({ lat: 0, lng: 0 })
	const [addresArray, setAddresArray] = useState([])

	const getAddressByLatLng = async (lat, lng) => {
		try {
			const geo = await Geocode.fromLatLng(lat, lng)
			setPosition(geo.results[0].geometry.location)
			setAddresArray(
				geo.results.map(loc => ({
					value: JSON.stringify(loc.geometry.location),
					label: loc.formatted_address,
				}))
			)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (companyEdit) {
			setPosition({ lat: parseFloat(companyEdit.latitude), lng: parseFloat(companyEdit.longitude) })
			getAddressByLatLng(parseFloat(companyEdit.latitude), parseFloat(companyEdit.longitude))
		}
	}, [companyEdit?.latitude, companyEdit?.longitude])

	const listJobFuntion = input => {
		return JobAPI.getJobFunc({ keyword: input })
	}

	return (
		<ReactModal
			ariaHideApp={false}
			className='edit-education-modal-content  '
			overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
			isOpen={visible}
			contentLabel='Minimal Modal Example'>
			<div className='flex justify-between items-center mb-3'>
				<h1 className='text-lg font-bold'>Edit Perusahaan</h1>
				<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
			</div>

			<hr />

			<form onSubmit={onSubmit}>
				<div className='flex flex-col mt-4'>
					<label htmlFor='email' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Email
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='email'
							type='email'
							placeholder='Deskripsi'
							className='w-full h-full focus:outline-none'
							value={companyEdit?.email}
							onChange={e => {
								setCompanyEdit({ ...companyEdit, email: e.target.value })
							}}
						/>
					</div>
				</div>
				<div className='flex md:justify-between items-center md:flex-row flex-col'>
					<div className='flex flex-col mt-4 w-full md:mr-1'>
						<label htmlFor='companyName' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Nama Perusahaan
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='companyName'
								type='text'
								placeholder='PT. Generasi Muda'
								className='w-full h-full focus:outline-none'
								value={companyEdit?.name}
								onChange={e => {
									setCompanyEdit({ ...companyEdit, name: e.target.value })
								}}
							/>
						</div>
					</div>

					<div className='flex flex-col mt-4 w-full md:ml-1'>
						<label htmlFor='phoneNumber' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> No. Telpon
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								inputMode='numeric'
								id='phoneNumber'
								type='text'
								placeholder='12'
								className='w-full h-full focus:outline-none flex-1'
								value={companyEdit?.phone}
								onChange={e => {
									setCompanyEdit({ ...companyEdit, phone: e.target.value })
								}}
							/>
						</div>
					</div>
				</div>

				<div className='flex md:justify-between items-center md:flex-row flex-col'>
					<div className='flex flex-col mt-4 w-full md:mr-1'>
						<label htmlFor='maxSall' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Jumlah Karyawan
						</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									minHeight: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							value={companySize.data.filter(
								comSize => parseInt(comSize.value) === parseInt(companyEdit?.companySizeID)
							)}
							// value={cities.data.filter(city => city.value === parseInt(userProfileEdit.data.cityID))[0]}
							options={companySize.data}
							onChange={val => setCompanyEdit({ ...companyEdit, companySizeID: val.value })}
						/>
					</div>
					<div className='flex flex-col mt-4 w-full md:ml-1'>
						<label htmlFor='expiredDate' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tanggal Berdiri
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='expiredDate'
								type='date'
								className='w-full h-full focus:outline-none flex-1'
								value={companyEdit?.founded}
								onChange={e => {
									setCompanyEdit({ ...companyEdit, founded: e.target.value })
								}}
							/>
						</div>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='description' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Tentang Perusahaan
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<textarea
							id='description'
							type='text'
							placeholder='Deskripsi'
							className='w-full h-full focus:outline-none'
							value={companyEdit?.description}
							onChange={e => {
								setCompanyEdit({ ...companyEdit, description: e.target.value })
							}}
						/>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='address' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Alamat
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<textarea
							id='address'
							type='text'
							placeholder='Deskripsi'
							className='w-full h-full focus:outline-none'
							value={companyEdit?.address}
							onChange={e => {
								setCompanyEdit({ ...companyEdit, address: e.target.value })
							}}
						/>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='headQuarter' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Alamat Kantor Pusat
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<textarea
							id='headQuarter'
							type='text'
							placeholder='Deskripsi'
							className='w-full h-full focus:outline-none'
							value={companyEdit?.headQuarter}
							onChange={e => {
								setCompanyEdit({ ...companyEdit, headQuarter: e.target.value })
							}}
						/>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Pilih Point Alamat
					</label>

					<Select
						styles={{
							control: (provided, state) => ({
								...provided,
								height: '43px',
								borderColor: '#a0aec0',
							}),
						}}
						onChange={options => {
							if (options) {
								let parsed = JSON.parse(options.value)
								setPosition(parsed)
								setCompanyEdit({
									...companyEdit,

									latitude: parsed.lat,
									longitude: parsed.lng,
								})
							} else {
								setPosition({ lat: companyEdit?.latitude, lng: companyEdit?.longitude })
								setCompanyEdit({
									...companyEdit,
									latitude: companyEdit?.latitude,
									longitude: companyEdit?.longitude,
								})
							}
						}}
						isClearable
						options={addresArray}
						onInputChange={_.debounce(input => {
							if (input.length === 0) {
								setAddresArray([])
							} else {
								Geocode.fromAddress(input)
									.then(geo => {
										let res = geo.results.map(loc => ({
											value: JSON.stringify(loc.geometry.location),
											label: loc.formatted_address,
										}))
										setAddresArray(res)
									})
									.catch(e => setAddresArray([]))
							}
						}, 400)}
					/>
					<small className='text-xs text-gray-600 ml-1 mt-2'>
						Kandidat memerlukan informasi ini agar bisa menemukan perusahaan anda dengan mudah.
					</small>
				</div>

				<LocationPicker
					zoom={15}
					containerElement={<div style={{ height: '100%' }}></div>}
					mapElement={<div className='h-64 rounded-md mt-4' />}
					defaultPosition={position}
					onChange={e => {
						setPosition({ lat: e.position.lat, lng: e.position.lng })
						getAddressByLatLng(e.position.lat, e.position.lng)
					}}
				/>

				<button
					type='submit'
					className={
						'p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black ' +
						(submitting && 'opacity-50')
					}>
					Ubah Perusahaan
				</button>
			</form>
		</ReactModal>
	)
}

export default EditCompanyModal
