import React, { useState } from 'react'
import moment from 'moment'
import JobAPI from '../../../services/JobAPI'
import { Link } from 'react-router-dom'
import gravatar from 'gravatar'

const AplicantCard = ({ applicant, showAction, onSuccess }) => {
	const [submitting, setSubmitting] = useState(false)
	const [error, setError] = useState(false)

	const onChangeAplikan = async (statusType, id) => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await JobAPI.changeStatusApplikan({ status: statusType }, id)
				onSuccess()
				setSubmitting(false)
			} catch (error) {
				console.log(error)
				setSubmitting(false)
			}
		}
	}

	return (
		<div className='flex border-b border-gray-300 py-4' key={applicant.ID}>
			<div>
				<Link to={`/applicant/${applicant.ID}?jobID=${applicant.jobID}`}>
					<img
						onError={() => setError(true)}
						src={
							error
								? gravatar.url(applicant.email, { s: 48, default: 'identicon' })
								: applicant.jobSeekerAvatar?.avatar ?? gravatar.url(applicant.email, { s: 48, default: 'identicon' })
						}
						className='rounded-full w-12 h-12 border-1 object-cover border-transparent border-indigo-400'
						alt='Ji Soo'
					/>
				</Link>
			</div>

			<div className='pl-4 pr-2 flex-1 '>
				<Link to={`/applicant/${applicant.jobSeekerID}?jobID=${applicant.jobID}`}>
					<h1 className='text-sm font-bold'>{applicant.name}</h1>
				</Link>
				<div>
					<i className='icofont-location-pin text-xs text-gray-600'></i>
					<span className='ml-1'>{applicant.city.name}</span>
				</div>
				<div>
					<i className='icofont-location-arrow text-xs text-gray-600'></i>
					<span className='ml-1'>{applicant.distance}</span>
				</div>
				<div>
					<i className='icofont-clock-time text-xs text-gray-600'></i>
					<span className='ml-1'>
						Applied on {moment(applicant.created, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}
					</span>
				</div>
			</div>

			{showAction && (
				<div className='flex float-right'>
					<i
						aria-disabled={submitting}
						className={
							'icofont-close text-red-500 text-sm p-1 ' +
							(submitting ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer ')
						}
						onClick={() => onChangeAplikan('rejected', applicant.ID)}></i>
					<i
						aria-disabled={submitting}
						className={
							'icofont-check-alt text-green-600 text-sm p-1 ' +
							(submitting ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer ')
						}
						onClick={() => onChangeAplikan('confirmed', applicant.ID)}></i>
				</div>
			)}
		</div>
	)
}

export default AplicantCard
