import React, { useState, useContext, useEffect } from 'react'
import ReactModal from 'react-modal'
import Select from 'react-select'
import years from '../../../utils/years'
import months from '../../../utils/months'
import { DataContext } from '../../../context/DataContext'
import MasterAPI from '../../../services/MasterAPI'
import { AuthContext } from '../../../context/AuthContext'
import { toast } from 'react-toastify'

const EducationModal = ({ visible, onCancel, mode = 'add', educationEdit, setEducationEdit }) => {
	const dataContext = useContext(DataContext)
	const authContext = useContext(AuthContext)

	const { educationTier } = dataContext
	const { getUserProfile } = authContext

	const [submitting, setSubmitting] = useState(false)
	const [formValue, setFormValue] = useState({
		educationTierID: '',
		schoolName: '',
		startYear: '',
		startMonth: '',
		endYear: '',
		endMonth: '',
		fieldStudy: '',
		isCurrent: 0,
	})

	const onLogin = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)

			if (mode === 'add') {
				try {
					await MasterAPI.addJobSeekerEducation(formValue)
					getUserProfile()
					setSubmitting(false)
					onCancel()
					toast.success('Sukses tambah data pendidikan')
				} catch (error) {
					toast.error('Gagal tambah data pendidikan')
					setSubmitting(false)
				}
			}

			if (mode === 'edit') {
				try {
					await MasterAPI.updateJobSeekerEducation(educationEdit, educationEdit?.ID)
					getUserProfile()
					setSubmitting(false)
					onCancel()
					toast.success('Sukses tambah data pendidikan')
				} catch (error) {
					toast.error('Gagal tambah data pendidikan')
					setSubmitting(false)
				}
			}
		}
	}

	return (
		<ReactModal
			className='edit-education-modal-content'
			overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
			isOpen={visible}
			contentLabel='Minimal Modal Example'>
			<div className='flex justify-between items-center mb-3'>
				<h1 className='text-lg font-bold'>Tambah Pendidikan</h1>
				<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
			</div>

			<hr />

			<form onSubmit={onLogin}>
				<div className='flex flex-col mt-4'>
					<label htmlFor='schoolName' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Nama Sekolah
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='schoolName'
							type='text'
							placeholder='SMK'
							className='w-full h-full focus:outline-none'
							value={mode === 'add' ? formValue.schoolName : educationEdit?.schoolName}
							onChange={e => {
								if (mode === 'add') {
									setFormValue({ ...formValue, schoolName: e.target.value })
								} else {
									setEducationEdit({ ...educationEdit, schoolName: e.target.value })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Tingkat Pendidikan
					</label>
					<Select
						styles={{
							control: (provided, state) => ({
								...provided,
								height: '43px',
								borderColor: '#a0aec0',
							}),
						}}
						isClearable
						value={
							mode === 'add'
								? educationTier.data.filter(edu => edu.value === parseInt(formValue.educationTierID))[0]
								: educationTier.data.filter(edu => edu.value === parseInt(educationEdit?.educationTierID))[0]
						}
						options={educationTier.data}
						onChange={val => {
							if (mode === 'add') {
								setFormValue({ ...formValue, educationTierID: val ? val.value : '' })
							} else {
								setEducationEdit({ ...educationEdit, educationTierID: val ? val.value : '' })
							}
						}}
					/>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='fieldStudy' className='font-semibold mb-1'>
						Jurusan
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='fieldStudy'
							type='text'
							placeholder='Teknik Komputer'
							className='w-full h-full focus:outline-none'
							value={mode === 'add' ? formValue.fieldStudy : educationEdit?.fieldStudy}
							onChange={e => {
								if (mode === 'add') {
									setFormValue({ ...formValue, fieldStudy: e.target.value })
								} else {
									setEducationEdit({ ...educationEdit, fieldStudy: e.target.value })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex justify-between items-center'>
					<div className='flex flex-col mt-4 mr-2 w-full'>
						<label className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tahun Masuk
						</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							isClearable
							value={
								mode === 'add'
									? years.filter(edu => edu.value === parseInt(formValue.startYear))[0]
									: years.filter(edu => edu.value === parseInt(educationEdit?.startYear))[0]
							}
							options={years}
							onChange={val => {
								if (mode === 'add') {
									setFormValue({ ...formValue, startYear: val ? val.value : '' })
								} else {
									setEducationEdit({ ...educationEdit, startYear: val ? val.value : '' })
								}
							}}
						/>
					</div>

					<div className='flex flex-col mt-4 ml-2 w-full'>
						<label className='font-semibold mb-1'>Bulan Masuk</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							value={
								mode === 'add'
									? months.filter(edu => edu.value === parseInt(formValue.startMonth))[0]
									: months.filter(edu => edu.value === parseInt(educationEdit?.startMonth))[0]
							}
							isClearable
							options={months}
							onChange={val => {
								if (mode === 'add') {
									setFormValue({ ...formValue, startMonth: val ? val.value : '' })
								} else {
									setEducationEdit({ ...educationEdit, startMonth: val ? val.value : '' })
								}
							}}
						/>
					</div>
				</div>

				{/* ========MONTH========= */}

				<div className='flex justify-between items-center'>
					<div className='flex flex-col mt-4 mr-2 w-full'>
						<label className='font-semibold mb-1'>Tahun Keluar</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							isClearable
							value={
								mode === 'add'
									? years.filter(edu => edu.value === parseInt(formValue.endYear))[0]
									: years.filter(edu => edu.value === parseInt(educationEdit?.endYear))[0]
							}
							options={years}
							onChange={val => {
								if (mode === 'add') {
									setFormValue({ ...formValue, endYear: val ? val.value : '' })
								} else {
									setEducationEdit({ ...educationEdit, endYear: val ? val.value : '' })
								}
							}}
						/>
					</div>

					<div className='flex flex-col mt-4 ml-2 w-full'>
						<label className='font-semibold mb-1'>Bulan Keluar</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							value={
								mode === 'add'
									? months.filter(edu => edu.value === parseInt(formValue.endMonth))[0]
									: months.filter(edu => edu.value === parseInt(educationEdit?.endMonth))[0]
							}
							isClearable
							options={months}
							onChange={val => {
								if (mode === 'add') {
									setFormValue({ ...formValue, endMonth: val ? val.value : '' })
								} else {
									setEducationEdit({ ...educationEdit, endMonth: val ? val.value : '' })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex items-center mt-4'>
					<input
						type='checkbox'
						name='isCurrent'
						id='isCurrent'
						onChange={e => {
							if (mode === 'add') {
								setFormValue({ ...formValue, isCurrent: e.target.checked ? 1 : 0 })
							} else {
								setEducationEdit({ ...educationEdit, isCurrent: e.target.checked ? 1 : 0 })
							}
						}}
						checked={
							mode === 'add'
								? parseInt(formValue.isCurrent) === 1
									? true
									: false
								: parseInt(educationEdit?.isCurrent) === 1
								? true
								: false
						}
					/>
					<label htmlFor='isCurrent' className='text-base ml-4'>
						Tampilkan psebagai pendidikan terakhir
					</label>
				</div>

				<button
					type='submit'
					className='p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black'>
					Tambah Pendidikan
				</button>
			</form>
		</ReactModal>
	)
}

export default EducationModal
