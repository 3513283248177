import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import LoginModal from '../../components/LoginModal'
import { DataContext } from '../../context/DataContext'
import _ from 'lodash'
import { AuthContext } from '../../context/AuthContext'
import LocationPicker from 'react-location-picker'
import Geocode from 'react-geocode'
import { Link, Redirect } from 'react-router-dom'
import API from '../../services/API'
import LoginModalCompany from '../../components/LoginModalCompany'

const RegisterCheck = ({ history }) => {
	const authContext = useContext(AuthContext)
	const { hasValidNIK, setHasValidNIK, validNIKData, setValidNIKData, submitting, setSubmitting } = authContext

	const [modalLoginVisible, setModalLoginVisible] = useState(false)
	const [modalLoginVisibleCompany, setModalLoginVisibleCompany] = useState(false)

	const [nik, setNik] = useState('')
	const [errorRegister, setErrorRegister] = useState(false)

	const onCheckNIK = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				const response = await API.checkNIK(nik)
				if (response.code === 200 && response.data) {
					setValidNIKData(response.data)
					setHasValidNIK(true)
					setSubmitting(false)

					history.push('/register')
				}
			} catch (error) {
				setErrorRegister(true)
				setSubmitting(false)
				setHasValidNIK(false)
			}
		}
	}

	return (
		<div className='container mx-auto'>
			<div className='text-center mt-16'>
				<h1 className='font-bold text-4xl'>Silahkan Buat</h1>
				<h1 className='font-bold text-4xl'>Profil Anda</h1>
			</div>

			<div className='register-container mx-auto mt-16 bg-white py-6 px-12 rounded-md shadow-md'>
				<div className='flex flex-col'>
					<label htmlFor='nameRegister' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Cek NIK Kamu Terlebih Dahulu
					</label>
					<div
						className={
							'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
							(errorRegister ? 'border-red-600' : 'border-gray-500')
						}>
						<input
							id='nameRegister'
							type='text'
							placeholder='09876578'
							className='w-full h-full focus:outline-none'
							value={nik}
							onChange={e => setNik(e.target.value)}
						/>
					</div>

					{errorRegister ? <span className='text-xs text-red-600'>Data kamu tidak di temukan nih...</span> : null}
				</div>

				<div className='flex justify-center'>
					{submitting ? (
						<button className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black cursor-not-allowed opacity-50'>
							Cek <i className='icofont-spinner-alt-1'></i>
						</button>
					) : (
						<button
							onClick={onCheckNIK}
							className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black'>
							Cek
						</button>
					)}
				</div>

				<p className='text-sm mt-4 text-gray-700 text-center'>
					Sudah punya akun ?{' '}
					<span className='text-indigo-500 cursor-pointer' onClick={() => setModalLoginVisible(true)}>
						Masuk
					</span>{' '}
				</p>

				<p className='text-xs mt-4 text-gray-700 text-center'>
					Anda perusahaan ?{' '}
					<Link to='/register-company'>
						<span className='text-indigo-500 cursor-pointer'>Klik disini</span>{' '}
					</Link>
				</p>
			</div>
			<LoginModal
				visible={modalLoginVisible}
				onCancel={() => setModalLoginVisible(false)}
				onLoginCompany={() => {
					setModalLoginVisibleCompany(true)
					setModalLoginVisible(false)
				}}
			/>
			<LoginModalCompany
				visible={modalLoginVisibleCompany}
				onCancel={() => setModalLoginVisibleCompany(false)}
				onLoginJobSeeker={() => {
					setModalLoginVisible(true)
					setModalLoginVisibleCompany(false)
				}}
			/>
		</div>
	)
}

export default RegisterCheck
