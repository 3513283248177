import Axios from 'axios'

let token
let role

try {
	if (localStorage.getItem('@user_token')) {
		token = localStorage.getItem('@user_token')
	}
	if (sessionStorage.getItem('@user_token')) {
		token = sessionStorage.getItem('@user_token')
	}
} catch (error) {
	token = null
}

try {
	if (localStorage.getItem('@role')) {
		role = localStorage.getItem('@role')
	}
	if (sessionStorage.getItem('@role')) {
		role = sessionStorage.getItem('@role')
	}
} catch (error) {
	role = null
}

Axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL
Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
Axios.defaults.headers.common['Role'] = role
Axios.defaults.headers.post['Content-Type'] = `multipart/form-data`

export default Axios
