import React from 'react'
const IS_JOBSEEKER = localStorage.getItem('@role') && localStorage.getItem('@role') === 'JobSeeker'

const EducationCard = ({ education, onEdit }) => {
	return (
		<div className='flex rounded-md border p-2 relative'>
			{IS_JOBSEEKER && (
				<span className='absolute right-0 top-0 p-2'>
					<i className='icofont-ui-edit text-xs cursor-pointer' onClick={onEdit}></i>
				</span>
			)}

			<div>
				<img
					src='https://www.kemdikbud.go.id/main/files/large/33ddc3bc2640689'
					alt='SMKN'
					className='h-12 w-12 object-cover rounded-sm'
				/>
			</div>

			<div className='px-6'>
				<h1 className='text-sm font-bold'>{education.schoolName}</h1>
				<h2 className='text-xs'>{education?.jobSeekerEducationDetail?.fieldStudy ?? ''}</h2>
				<h2 className='text-xs text-gray-600'>
					{education?.jobSeekerEducationDetail?.startYear ?? ''}{' '}
					{` - ${education?.jobSeekerEducationDetail?.endYear}` ?? ''}
				</h2>
			</div>
		</div>
	)
}

export default EducationCard
