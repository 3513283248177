import React, { useState, useContext, useEffect, Fragment } from 'react'
import ReactModal from 'react-modal'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import years from '../../../utils/years'
import months from '../../../utils/months'
import { DataContext } from '../../../context/DataContext'
import MasterAPI from '../../../services/MasterAPI'
import JobAPI from '../../../services/JobAPI'
import _ from 'lodash'
import ReactQuill from 'react-quill'
import NumberFormat from 'react-number-format'
import moment from 'moment'

import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'

const CreateJobModal = ({
	visible,
	onCancel,
	mode = 'add',
	onSucces = () => null,
	jobEdit = null,
	setJobEdit,
	archived = false,
}) => {
	const dataContext = useContext(DataContext)

	const { defaultJobFunction, jobType, skills } = dataContext

	const [submitting, setSubmitting] = useState(false)
	const [formValue, setFormValue] = useState({
		jobFunctionID: '',
		jobTypeID: '',
		experienceMin: '',
		experienceMax: '',
		vacancies: '',
		about: '',
		salaryMin: '',
		salaryMax: '',
		expired: '',
		title: '',
	})
	const [skillsForm, setSkillsForm] = useState([])
	const [skillDelete, setSkillDelete] = useState([])
	const [showDelete, setShowDelete] = useState(false)

	const onSubmit = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)

			if (mode === 'add') {
				try {
					const response = await JobAPI.createJob(formValue)
					if (response.data.ID) {
						await JobAPI.assignSkills(skillsForm, [], response.data.ID)
					}
					onSucces()
					setSubmitting(false)
					onCancel()
					toast.success('Berhasil tambah data')
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					toast.error('Gagal tambah data')
				}
			}

			if (mode === 'edit') {
				try {
					await JobAPI.editJob(jobEdit, jobEdit?.ID)
					await JobAPI.assignSkills(skillsForm, skillDelete, jobEdit?.ID)
					onSucces()
					setSubmitting(false)
					onCancel()
					toast.success('Berhasil ubah data')
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					toast.error('Gagal ubah data')
				}
			}
		}
	}

	const listJobFuntion = input => {
		return JobAPI.getJobFunc({ keyword: input })
	}

	useEffect(() => {
		if (jobEdit === null) {
			setSkillsForm([])
		}
	}, [jobEdit])

	return (
		<ReactModal
			ariaHideApp={false}
			className='edit-education-modal-content '
			overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
			isOpen={visible}
			contentLabel='Minimal Modal Example'>
			<div className='flex justify-between items-center mb-3'>
				<h1 className='text-lg font-bold'>{mode === 'add' ? 'Tambah' : 'Edit'} Pekerjaan</h1>
				<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
			</div>

			<hr />

			<form onSubmit={onSubmit}>
				<div className='flex flex-col mt-4'>
					<label htmlFor='jobTitle' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Nama Pekerjaan
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='jobTitle'
							type='text'
							placeholder='Developer'
							className='w-full h-full focus:outline-none'
							value={mode === 'add' ? formValue.title : jobEdit?.title}
							onChange={e => {
								if (mode === 'add') {
									setFormValue({ ...formValue, title: e.target.value })
								} else {
									setJobEdit({ ...jobEdit, title: e.target.value })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex md:justify-between items-center md:flex-row flex-col'>
					<div className='flex flex-col mt-4 mr-2 w-full'>
						<label className='font-semibold mb-1'>Fungsi Pekerjaan</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							options={defaultJobFunction}
							isClearable
							value={
								mode === 'add'
									? defaultJobFunction.filter(jobFun => parseInt(jobFun.value) === parseInt(formValue.jobFunctionID))
									: defaultJobFunction.filter(jobFun => parseInt(jobFun.value) === parseInt(jobEdit?.jobFunctionID))
							}
							onChange={val => {
								if (mode === 'add') {
									setFormValue({ ...formValue, jobFunctionID: val ? val.value : '' })
								} else {
									setJobEdit({ ...jobEdit, jobFunctionID: val ? val.value : '' })
								}
							}}
						/>
					</div>

					<div className='flex flex-col mt-4 mr-2 w-full'>
						<label className='font-semibold mb-1'>Jenis Pekerjaan</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							value={
								mode === 'add'
									? jobType.filter(jobFun => parseInt(jobFun.value) === parseInt(formValue.jobTypeID))
									: jobType.filter(jobFun => parseInt(jobFun.value) === parseInt(jobEdit?.jobTypeID))
							}
							isClearable
							options={jobType}
							onChange={val => {
								if (mode === 'add') {
									setFormValue({ ...formValue, jobTypeID: val ? val.value : '' })
								} else {
									setJobEdit({ ...jobEdit, jobTypeID: val ? val.value : '' })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex md:justify-between items-center md:flex-row flex-col'>
					<div className='flex flex-col mt-4 w-full md:mr-1'>
						<label htmlFor='minExp' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Pengalaman Minimal
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='minExp'
								type='number'
								placeholder='1'
								className='w-full h-full focus:outline-none flex-1'
								value={mode === 'add' ? formValue.experienceMin : jobEdit?.experienceMin}
								onChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, experienceMin: e.target.value })
									} else {
										setJobEdit({ ...jobEdit, experienceMin: e.target.value })
									}
								}}
							/>
							<span className='text-sm'>Tahun</span>
						</div>
					</div>

					<div className='flex flex-col mt-4 w-full md:ml-1'>
						<label htmlFor='maxExp' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Pengalaman Maksimal
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='maxExp'
								type='number'
								placeholder='5'
								className='w-full h-full focus:outline-none flex-1'
								value={mode === 'add' ? formValue.experienceMax : jobEdit?.experienceMax}
								onChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, experienceMax: e.target.value })
									} else {
										setJobEdit({ ...jobEdit, experienceMax: e.target.value })
									}
								}}
							/>
							<span className='text-sm'>Tahun</span>
						</div>
					</div>
				</div>

				<div className='flex md:justify-between items-center md:flex-row flex-col'>
					<div className='flex flex-col mt-4 w-full md:mr-1'>
						<label htmlFor='minSall' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Gaji Minimal
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<NumberFormat
								id='minSall'
								placeholder='7.000.000'
								onValueChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, salaryMin: e.value })
									} else {
										setJobEdit({ ...jobEdit, salaryMin: e.value })
									}
								}}
								value={mode === 'add' ? formValue.salaryMin : jobEdit?.salaryMin}
								className='w-full h-full focus:outline-none flex-1'
								thousandSeparator={'.'}
								decimalSeparator={','}
								prefix={'Rp'}
							/>
						</div>
					</div>

					<div className='flex flex-col mt-4 w-full md:ml-1'>
						<label htmlFor='maxSall' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Gaji Maksimal
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<NumberFormat
								id='maxSall'
								placeholder='7.000.000'
								onValueChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, salaryMax: e.value })
									} else {
										setJobEdit({ ...jobEdit, salaryMax: e.value })
									}
								}}
								value={mode === 'add' ? formValue.salaryMax : jobEdit?.salaryMax}
								className='w-full h-full focus:outline-none flex-1'
								thousandSeparator={'.'}
								decimalSeparator={','}
								prefix={'Rp'}
							/>
						</div>
					</div>
				</div>

				<div className='flex md:justify-between items-center md:flex-row flex-col'>
					<div className='flex flex-col mt-4 w-full md:mr-1'>
						<label htmlFor='vacancies' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Juamlah Yang Akan Diterima
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='vacancies'
								type='text'
								placeholder='12'
								className='w-full h-full focus:outline-none flex-1'
								value={mode === 'add' ? formValue.vacancies : jobEdit?.vacancies}
								onChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, vacancies: e.target.value })
									} else {
										setJobEdit({ ...jobEdit, vacancies: e.target.value })
									}
								}}
							/>
							{/* <span className='text-sm'>Tahun</span> */}
						</div>
					</div>

					<div className='flex flex-col mt-4 w-full md:ml-1'>
						<label htmlFor='expiredDate' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tanggal Berakhir
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='expiredDate'
								type='date'
								className='w-full h-full focus:outline-none flex-1'
								value={
									mode === 'add'
										? formValue.expired
										: moment(jobEdit?.expired, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
								}
								onChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, expired: e.target.value })
									} else {
										setJobEdit({ ...jobEdit, expired: e.target.value })
									}
								}}
							/>
						</div>
					</div>
				</div>

				{mode === 'add' ? (
					<div className='flex flex-col mt-4'>
						<label htmlFor='maxSall' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Skills
						</label>
						<CreatableSelect
							styles={{
								control: (provided, state) => ({
									...provided,
									minHeight: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							value={skillsForm}
							isMulti
							options={skills}
							onChange={val => setSkillsForm(val)}
						/>
					</div>
				) : (
					<Fragment>
						<div className='flex flex-col mt-4'>
							<label htmlFor='schoolName' className='font-semibold mb-1'>
								<span className='text-xs text-red-600'>*</span> Ubah Skill
							</label>
							<div className='w-full flex flex-wrap'>
								{jobEdit?.jobSkills.map((skill, index) => (
									<span
										className={
											'px-3 py-1 bg-gray-300 rounded-full m-1 ' +
											(skillDelete.includes(skill.ID) ? 'opacity-50' : 'opacity-100')
										}
										key={index}>
										{skill.jobSeekerSkill.label}{' '}
										{skillDelete.includes(skill.ID) ? (
											<strong
												className='cursor-pointer'
												onClick={() => setSkillDelete(skillDelete.filter(del => del !== skill.ID))}>
												⚬
											</strong>
										) : (
											<strong className='cursor-pointer' onClick={() => setSkillDelete([...skillDelete, skill.ID])}>
												✗
											</strong>
										)}
									</span>
								))}
							</div>
						</div>

						<div className='flex flex-col mt-4'>
							<label htmlFor='maxSall' className='font-semibold mb-1'>
								<span className='text-xs text-red-600'>*</span> Tambah Skills
							</label>
							<CreatableSelect
								value={skillsForm}
								styles={{
									control: (provided, state) => ({
										...provided,
										minHeight: '43px',
										borderColor: '#a0aec0',
									}),
								}}
								isMulti
								options={skills}
								onChange={val => setSkillsForm(val)}
							/>
						</div>
					</Fragment>
				)}

				<div className='flex flex-col mt-4'>
					<label htmlFor='jobTitle' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Deskripsi Pekerjaan
					</label>
					<ReactQuill
						theme='snow'
						id='aboutJob'
						className='rounded-sm'
						value={mode === 'edit' ? jobEdit?.about : formValue.about}
						onChange={value => {
							if (mode === 'add') {
								setFormValue({ ...formValue, about: value })
							} else {
								setJobEdit({ ...jobEdit, about: value })
							}
						}}
					/>
				</div>

				<button
					type='submit'
					className={
						'p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black ' +
						(submitting && 'opacity-50')
					}>
					{mode === 'add' ? 'Tambah' : 'Edit'} Pekerjaan
				</button>

				<div className='ml-auto relative w-1/4'>
					{mode === 'edit' && jobEdit?.published !== 0 && (
						<button
							onClick={() => setShowDelete(true)}
							type='button'
							className='p-2 text-white bg-red-500 w-full font-semibold rounded-md flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-xs ml-auto'>
							Arsip pekerjaan
						</button>
					)}

					{mode === 'edit' && jobEdit?.published === 0 && (
						<button
							onClick={() => {
								if (!submitting) {
									setSubmitting(true)
									JobAPI.editJob({ published: '1' }, jobEdit?.ID)
										.then(() => {
											onSucces()
											setSubmitting(false)
											onCancel()
											toast.success('Berhasil terbitkan pekerjaan')
										})
										.catch(() => {
											setSubmitting(false)
											toast.error('Gagal terbitkan pekerjaan')
										})
								}
							}}
							type='button'
							className='p-2 text-white bg-green-500 w-full font-semibold rounded-md flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-xs ml-auto'>
							Terbitkan pekerjaan
						</button>
					)}

					{showDelete && (
						<div className='py-2 px-4 rounded-sm bg-white shadow-lg absolute top-0 left-0 transform -translate-y-12 text-sm'>
							<div className='flex justify-between items-center mb-3'>
								<h1>Yakin arsip pekerjaan ?</h1>
							</div>

							<div className='flex justify-between items-center mb-3'>
								<button
									disabled={submitting}
									className='py-1 px-2 bg-gray-400 text-black rounded-sm w-full mr-1 focus:outline-none'
									onClick={() => {
										if (!submitting) {
											setSubmitting(true)
											JobAPI.editJob({ published: '0' }, jobEdit?.ID)
												.then(() => {
													onSucces()
													setSubmitting(false)
													onCancel()
													toast.success('Berhasil hapus data')
												})
												.catch(() => {
													setSubmitting(false)
													toast.error('Gagal hapus data')
												})
										}
									}}>
									Ya
								</button>
								<button
									className='py-1 px-2 bg-green-500 text-white rounded-sm w-full ml-1 focus:outline-none'
									onClick={() => setShowDelete(false)}>
									Tidak
								</button>
							</div>
						</div>
					)}
				</div>
			</form>
		</ReactModal>
	)
}

export default CreateJobModal
