import React, { useState, useEffect, Fragment, useContext } from 'react'
import JobCard from '../../components/JobCard'
import JobAPI from '../../services/JobAPI'
import LoadingState from '../../components/LoadingState'
import moment from 'moment'
import { toast } from 'react-toastify'
import { AuthContext } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import AplicantCard from './components/AplicantCard'
import gravatar from 'gravatar'

const TOKEN = localStorage.getItem('@user_token')
const IS_COMPANY = localStorage.getItem('@role') && localStorage.getItem('@role') === 'Company'
const IS_JOBSEEKER = localStorage.getItem('@role') && localStorage.getItem('@role') === 'JobSeeker'

const Detail = ({ match }) => {
	const authContext = useContext(AuthContext)

	const { userProfile, companyProfile } = authContext

	const [jobDetail, setJobDetail] = useState({ data: null, isLoading: true })
	const [applicants, setApplicants] = useState({ data: [], isLoading: true })
	const [applicantsConfirmed, setApplicantsConfirmed] = useState({ data: [], isLoading: true })
	const [applicantsRejected, setApplicantsRejected] = useState({ data: [], isLoading: true })
	const [submitting, setSubmitting] = useState(false)
	const [selectedTab, setSelectedTab] = useState(1)
	const [isApplied, setIsApplied] = useState(false)
	const [errorAvatar, setErrorAvatar] = useState(false)

	const tabs = [
		{
			id: 1,
			label: 'Pending',
		},
		{
			id: 2,
			label: 'Confirmed',
		},
		{
			id: 3,
			label: 'Rejected',
		},
	]

	const getJobDetail = async () => {
		try {
			const response = await JobAPI.getJobDetail(match.params.id)

			setJobDetail({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}
	const checkIsApplied = async () => {
		try {
			const response = await JobAPI.checkIsApplied({ jobID: match.params.id })

			setIsApplied(response.data.joined)
		} catch (error) {
			console.log(error)
		}
	}

	const getApplicants = async () => {
		try {
			const response = await JobAPI.getApplicant({
				jobID: match.params.id,
				status: 'pending',
				sort: 'distance',
				order: 'asc',
			})

			setApplicants({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getApplicantsConfirmed = async () => {
		try {
			const response = await JobAPI.getApplicant({
				jobID: match.params.id,
				status: 'confirmed',
				sort: 'distance',
				order: 'asc',
			})

			setApplicantsConfirmed({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getApplicantsRejected = async () => {
		try {
			const response = await JobAPI.getApplicant({
				jobID: match.params.id,
				status: 'rejected',
				sort: 'distance',
				order: 'asc',
			})

			setApplicantsRejected({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const onApplyJob = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await JobAPI.applyJob({ jobID: match.params.id, jobSeekerID: userProfile.data.ID })
				setSubmitting(false)
				toast.success('Sukses mengajukan! Perusahaan akan menghubungimu')
				checkIsApplied()
			} catch (error) {
				setSubmitting(false)

				toast.error('Gagal mengajukan')
			}
		}
	}

	const renderWhichTab = () => {
		switch (selectedTab) {
			case 1:
				return (
					<div className='mt-2'>
						{applicants.isLoading ? (
							<div className='w-full text-center text-sm'>
								<img
									src={require('../../assets/images/undraw_Steps_re_odoy.png')}
									className='h-32 w-32 mx-auto'
									alt='Loading'
								/>
								<h1>Loading ... </h1>
							</div>
						) : !applicants.isLoading && applicants.data.length === 0 ? (
							<div className='w-full text-center text-sm'>
								<img src={require('../../assets/images/empty_data.png')} className='h-32 w-32 mx-auto' alt='Empty' />
								<h1>Belum ada pelamar ... </h1>
							</div>
						) : (
							applicants.data.map(applicant => (
								<AplicantCard
									applicant={applicant}
									showAction={true}
									onSuccess={() => {
										getApplicants()
										getApplicantsConfirmed()
										getApplicantsRejected()
									}}
								/>
							))
						)}
					</div>
				)

			case 2:
				return (
					<div className='mt-2'>
						{applicantsConfirmed.isLoading ? (
							<div className='w-full text-center text-sm'>
								<img
									src={require('../../assets/images/undraw_Steps_re_odoy.png')}
									className='h-32 w-32 mx-auto'
									alt='Loading'
								/>
								<h1>Loading ... </h1>
							</div>
						) : !applicantsConfirmed.isLoading && applicantsConfirmed.data.length === 0 ? (
							<div className='w-full text-center text-sm'>
								<img src={require('../../assets/images/empty_data.png')} className='h-32 w-32 mx-auto' alt='Empty' />
								<h1>Belum ada pelamar ... </h1>
							</div>
						) : (
							applicantsConfirmed.data.map(applicant => <AplicantCard applicant={applicant} />)
						)}
					</div>
				)

			case 3:
				return (
					<div className='mt-2'>
						{applicantsRejected.isLoading ? (
							<div className='w-full text-center text-sm'>
								<img
									src={require('../../assets/images/undraw_Steps_re_odoy.png')}
									className='h-32 w-32 mx-auto'
									alt='Loading'
								/>
								<h1>Loading ... </h1>
							</div>
						) : !applicantsRejected.isLoading && applicantsRejected.data.length === 0 ? (
							<div className='w-full text-center text-sm'>
								<img src={require('../../assets/images/empty_data.png')} className='h-32 w-32 mx-auto' alt='Empty' />
								<h1>Belum ada pelamar ... </h1>
							</div>
						) : (
							applicantsRejected.data.map(applicant => <AplicantCard applicant={applicant} />)
						)}
					</div>
				)
		}
	}

	useEffect(() => {
		getJobDetail()
		getApplicants()
		getApplicantsConfirmed()
		getApplicantsRejected()
		checkIsApplied()
	}, [])

	const translateApplicants = [applicants, applicantsConfirmed, applicantsRejected]

	return (
		<div className='mx-auto pb-16'>
			{jobDetail.isLoading ? (
				<LoadingState />
			) : (
				<Fragment>
					<div className='bg-white pb-8 pt-16 shadow-sm'>
						<div className='container mx-auto px-6 md:max-w-5xl w-full'>
							<div className='flex'>
								<div>
									<img
										// src={
										// 	jobDetail.data.company.companyAvatar?.avatar ??
										// 	'https://cdn.pixabay.com/photo/2019/12/14/07/21/building-4694350_1280.png'
										// }

										onError={() => {
											setErrorAvatar(true)
										}}
										src={
											errorAvatar
												? gravatar.url(jobDetail.data.company.email, { s: 150, default: 'identicon' })
												: jobDetail.data.company.companyAvatar?.avatar ??
												  gravatar.url(jobDetail.data.company.email, { s: 150, default: 'identicon' })
										}
										alt='Company Logo'
										className='w-20 h-20 object-cover rounded-sm'
									/>
								</div>

								<div className='flex-1 ml-6'>
									<h1 className='text-2xl font-bold'>{jobDetail.data.title}</h1>
									<Link to={`/company/${jobDetail.data.company.ID}`}>
										<p className='text-xs  text-blue-700'>{jobDetail.data.company.name}</p>
									</Link>
									{/* <p className='text-sm'>Fitness</p> */}
									<p className='text-sm'>{jobDetail.data.company.city.name}</p>

									<div className='text-xs mt-2'>
										<i className='icofont-clock-time'></i>
										<span className='job-detail-footer ml-2'>
											Date created: {moment(jobDetail.data.created, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}
										</span>
										<span>
											Last updated: {moment(jobDetail.data.updated, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}
										</span>
									</div>
									<div className='text-xs mt-2'>
										<i className='icofont-clock-time'></i>
										<span className='ml-2'>
											Expired : {moment(jobDetail.data.expired, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}
										</span>
									</div>
									<div className='text-xs mt-2'>
										<i className='icofont-briefcase'></i>
										<span className='ml-2'>Type: {jobDetail.data.jobType?.label}</span>
									</div>
									<div className='text-xs mt-2'>
										<i className='icofont-briefcase'></i>
										<span className='ml-2'>
											Experience: {jobDetail.data.experienceMin} - {jobDetail.data.experienceMax} years
										</span>
									</div>
								</div>
							</div>

							<hr className='my-4' />

							{IS_JOBSEEKER && (
								<div className='flex justify-end'>
									<button
										onClick={onApplyJob}
										className={
											`bg-indigo-500 text-white font-thin px-6 py-2 rounded-full text-sm focus:outline-none ` +
											(submitting || isApplied ? 'cursor-not-allowed opacity-50' : '')
										}>
										{submitting ? 'Mengajukan ...' : isApplied ? 'Kamu sudah mengajukan' : 'Ajukan Pekerjaan'}
									</button>
								</div>
							)}
						</div>
					</div>

					<div className='container flex md:flex-row flex-col mx-auto md:px-2 px-6 md:max-w-5xl w-full mt-6'>
						{IS_COMPANY && companyProfile.isLoading ? null : companyProfile.data?.ID !==
						  jobDetail.data?.company.ID ? null : (
							<div className='bg-white block md:hidden border rounded-md px-4 py-2 w-full text-xs md:mb-0 mb-4'>
								<h1 className='text-base font-bold'>Daftar Pelamar</h1>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								<div className='flex w-full justify-evenly border-b pb-3'>
									{tabs.map(tab => (
										<button
											key={tab.id}
											onClick={() => setSelectedTab(tab.id)}
											className={
												'focus:outline-none rounded-sm px-3 py-1 hover:shadow-none transition duration-100 ' +
												(selectedTab === tab.id ? 'bg-indigo-500 text-white shadow-lg' : 'bg-gray-400 text-gray-800')
											}>
											{tab.label}
										</button>
									))}
								</div>

								{renderWhichTab()}
							</div>
						)}

						<div className='flex-1 md:pr-2'>
							<div className='bg-white border rounded-md p-6'>
								<h1 className='font-bold text-xl'>Job description & requirements</h1>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								<div
									className='text-sm jobdesk-container'
									dangerouslySetInnerHTML={{ __html: jobDetail.data.about }}></div>
							</div>

							{jobDetail.data.jobSkills.length === 0 ? null : (
								<div className='bg-white border rounded-md p-6 mt-4'>
									<h1 className='font-bold text-xl'>Required skills</h1>
									<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

									<div className='w-full flex flex-wrap'>
										{jobDetail.data.jobSkills.map(skill => (
											<span key={skill.ID} className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>
												{skill.jobSeekerSkill.label}
											</span>
										))}
									</div>
								</div>
							)}
						</div>

						<div className='hidden md:block w-1/3 pl-2'>
							<Fragment>
								<div className='bg-white border rounded-md px-4 py-2 w-full text-xs'>
									<h1 className='text-base font-bold'>About Company</h1>
									<div className='w-20 h-px bg-indigo-500 mt-2 mb-4'></div>

									<p>{jobDetail.data.company.description}</p>
									<div className='mt-4'>
										<p>Location</p>
										<p className='font-semibold'>{jobDetail.data.company.city.name}</p>
									</div>

									<hr className='mt-4' />

									<div className='my-2 text-right'>
										<Link to={`/company/${jobDetail.data.company.ID}`}>
											<span className='text-blue-600 cursor-pointer'>
												Company Profiles <i className='icofont-caret-right'></i>
											</span>
										</Link>
									</div>
								</div>
							</Fragment>

							<br />

							{IS_COMPANY && companyProfile.isLoading ? null : companyProfile.data?.ID !==
							  jobDetail.data?.company.ID ? null : (
								<div className='bg-white border rounded-md px-4 py-2 w-full text-xs'>
									<h1 className='text-base font-bold'>Daftar Pelamar</h1>
									<div className='w-20 h-px bg-gray-400 mt-2 mb-6'></div>

									<div className='flex w-full justify-evenly border-b pb-3'>
										{tabs.map(tab => (
											<button
												key={tab.id}
												onClick={() => setSelectedTab(tab.id)}
												className={
													'focus:outline-none rounded-sm px-3 py-1 hover:shadow-none transition duration-100 relative ' +
													(selectedTab === tab.id ? 'bg-indigo-500 text-white shadow-lg' : 'bg-gray-400 text-gray-800')
												}>
												{tab.label}{' '}
												{translateApplicants[tab.id - 1].data.length > 0 && (
													<span
														style={{ position: 'absolute', top: -10, right: -6, fontSize: '0.5rem', paddingTop: '2px' }}
														className='bg-pink-500 rounded-full font-semibold h-4 w-4 text-center text-white'>
														{translateApplicants[tab.id - 1].data.length}
													</span>
												)}
											</button>
										))}
									</div>

									{renderWhichTab()}
								</div>
							)}
						</div>
					</div>
				</Fragment>
			)}
		</div>
	)
}

export default Detail
