import React, { Fragment, useState } from 'react'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import Input from '../../../components/Input'
import { toast, ToastContainer } from 'react-toastify'
import API from '../../../services/API'

const PasswordModalCompany = ({ visible, onCancel, onSuccess = () => null }) => {
	const [submitting, setSubmitting] = useState(false)
	const [passwordForm, setPaswordForm] = useState({
		lastPassword: '',
		newPassword: '',
		confirmedPassword: '',
	})
	const [errors, setErrors] = useState(null)

	const onChangePassword = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.changePassword(passwordForm)
				setSubmitting(false)
				onSuccess()
				onCancel()
				toast.success('Berhasil ubah password')
			} catch (error) {
				console.log(error)
				setSubmitting(false)
				toast.error('Gagal ubah password')
				setErrors(error?.response?.data?.errors)
			}
		}
	}

	return (
		<ReactModal
			className='login-modal-content password-modal-content'
			overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
			isOpen={visible}
			contentLabel='Minimal Modal Example'>
			<div className='flex justify-between items-center mb-3'>
				<h1 className='text-lg font-bold'>Ubah password</h1>
				<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
			</div>

			<hr />

			<form>
				<Input
					name='userPasswordLogin'
					className='mt-3'
					label='Password Lama'
					icon={<i className='icofont-ui-lock'></i>}
					type='password'
					placeholder='Password'
					value={passwordForm.lastPassword}
					onChange={e => setPaswordForm({ ...passwordForm, lastPassword: e.target.value })}
				/>
				{errors ? (
					errors.lastPassword ? (
						<span className='text-xs text-red-600'>{errors.lastPassword[0]}</span>
					) : null
				) : null}

				<Input
					name='userPasswordLogin'
					className='mt-3'
					label='Password baru'
					icon={<i className='icofont-ui-lock'></i>}
					type='password'
					placeholder='Password'
					value={passwordForm.newPassword}
					onChange={e => setPaswordForm({ ...passwordForm, newPassword: e.target.value })}
				/>
				{errors ? (
					errors.newPassword ? (
						<span className='text-xs text-red-600'>{errors.newPassword[0]}</span>
					) : null
				) : null}
				<Input
					name='userPasswordLogin'
					className='mt-3'
					label='Konfirmasi password'
					icon={<i className='icofont-ui-lock'></i>}
					type='password'
					placeholder='Password'
					value={passwordForm.confirmedPassword}
					onChange={e => setPaswordForm({ ...passwordForm, confirmedPassword: e.target.value })}
				/>
				{errors ? (
					errors.confirmedPassword ? (
						<span className='text-xs text-red-600'>{errors.confirmedPassword[0]}</span>
					) : null
				) : null}

				{errors && errors.errors ? (
					<div className='my-4 bg-red-500 rounded-md w-full py-2 px-4 text-sm text-white flex justify-between items-center'>
						<p>{errors.errors}</p>

						<span className='cursor-pointer font-bold' onClick={() => setErrors(null)}>
							x
						</span>
					</div>
				) : null}

				<button
					onClick={onChangePassword}
					type='submit'
					className={`p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black ${
						submitting ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'
					} `}>
					Ubah password{' '}
					{submitting && (
						<Fragment>
							{' '}
							&nbsp; <i className='icofont-spinner-alt-1'></i>
						</Fragment>
					)}
				</button>
			</form>
		</ReactModal>
	)
}

export default PasswordModalCompany
