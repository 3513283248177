import React, { useContext, Fragment, useState, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import LoadingState from '../../components/LoadingState'
import JobAPI from '../../services/JobAPI'
import JobCard from '../../components/JobCard'
import CreateJobModal from './components/CreateJobModal'
import { JobContext } from '../../context/JobContext'
import MasterAPI from '../../services/MasterAPI'
import gravatar from 'gravatar'

const Detail = ({ match }) => {
	const authContext = useContext(AuthContext)
	const jobContext = useContext(JobContext)

	const [companyProfile, setCompanyProfile] = useState({ data: null, isLoading: true })
	const [jobs, setJobs] = useState({ data: [], isLoading: true })
	const [errorAvatar, setErrorAvatar] = useState(false)

	const fetchCompanyJobs = async () => {
		try {
			if (!companyProfile.isLoading) {
				setJobs({ ...jobs, isLoading: true })
				const response = await JobAPI.getJobs({ companyID: companyProfile.data.ID })
				setJobs({
					isLoading: false,
					data: response.data.list,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const getCompanyProfile = async () => {
		try {
			const response = await MasterAPI.detailCompany(match.params.id)
			setCompanyProfile({
				isLoading: false,
				data: response.data,
			})
			// setUserProfileEdit({
			// 	isLoading: false,
			// 	data: response.data,
			// })
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getCompanyProfile()
	}, [])

	useEffect(() => {
		fetchCompanyJobs()
	}, [companyProfile.isLoading])

	return (
		<div className='mx-auto pb-16'>
			{companyProfile.isLoading ? (
				<LoadingState />
			) : (
				<Fragment>
					{/* <ToastContainer /> */}
					<div className='bg-white pb-8 pt-16 shadow-sm'>
						<div className='container mx-auto px-6 md:max-w-5xl w-full'>
							<div className='flex'>
								<div className='flex-1 ml-6'>
									<h1 className='text-2xl font-bold'>{companyProfile.data.name}</h1>

									<div className='text-xs mt-2'>
										<i className='icofont-location-pin text-xs'></i>
										{/* <span className='job-detail-footer ml-2'>Date created:</span> */}
										<span className='ml-2'>{companyProfile.data.city.name}</span>
									</div>
									<div className='text-xs mt-2'>
										<i className='icofont-ui-user'></i>
										<span className='ml-2'>{companyProfile.data.companySize.label}</span>
									</div>
								</div>

								<div>
									<img
										onError={() => {
											setErrorAvatar(true)
										}}
										src={
											errorAvatar
												? gravatar.url(companyProfile.data.email, { s: 150, default: 'identicon' })
												: companyProfile.data.companyAvatar?.avatar ??
												  gravatar.url(companyProfile.data.email, { s: 150, default: 'identicon' })
										}
										alt='Company Logo'
										className='w-20 h-20 object-cover rounded-sm'
									/>
								</div>
							</div>

							{/* <div className='flex justify-end'>
						<button
							// onClick={onApplyJob}
							className={
								`bg-indigo-500 text-white font-thin px-6 py-2 rounded-full text-sm focus:outline-none `
								// (submitting ? 'cursor-not-allowed opacity-50' : '')
							}>
							Ajukan Pekerjaan
						</button>
					</div> */}
						</div>
					</div>

					<div className='container flex mx-auto md:px-2 px-6 md:max-w-5xl w-full mt-6'>
						<div className='flex-1 pr-2'>
							<div className='bg-white border rounded-md p-6'>
								<h1 className='font-bold text-xl'>Tentang Perusahaan</h1>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								<div className='text-sm jobdesk-container'>
									<p>{companyProfile.data.description}</p>
								</div>
							</div>

							{/* <div className='bg-white border rounded-md p-6 mt-4'>
								<h1 className='font-bold text-xl'>Required skills</h1>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								<div className='w-full flex flex-wrap'>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>Javascript</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>HTML</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>CSS</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>Web Development</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>SCSS</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>React JS</span>
									<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>Node JS</span>
								</div>
							</div> */}

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Pekerjaan</h1>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								{jobs.isLoading ? (
									<div className='w-full text-center text-sm'>
										<img
											src={require('../../assets/images/undraw_Steps_re_odoy.png')}
											className='h-32 w-32 mx-auto'
											alt='Loading'
										/>
										<h1>Loading ... </h1>
									</div>
								) : !jobs.isLoading && jobs.data.length === 0 ? (
									<div className='w-full text-center text-sm'>
										<img
											src={require('../../assets/images/empty_data.png')}
											className='h-32 w-32 mx-auto'
											alt='Empty'
										/>
										<h1>Belum ada pekerjaan ... </h1>
									</div>
								) : (
									<div className='text-sm jobdesk-container'>
										<div className='grid md:grid-cols-2 gap-3'>
											{jobs.data.map(job => (
												<JobCard key={job.ID} job={job} />
											))}
										</div>
									</div>
								)}
							</div>
						</div>

						<div className='hidden md:block w-1/3 pl-2'>
							{/* <div className='bg-white border rounded-md px-4 py-2 w-full text-xs'>
								<h1 className='text-base font-bold'>About Company</h1>
								<div className='w-20 h-px bg-indigo-500 mt-2 mb-4'></div>

								<p>asdasdasda</p>
								<div className='mt-4'>
									<p>Location</p>
									<p className='font-semibold'>asdasda</p>
								</div>
								<div className='mt-4'>
									<p>Industry</p>
									<p className='font-semibold'>Fitness</p>
								</div>

								<hr className='mt-4' />

								<div className='my-2 text-right'>
									<span className='text-blue-600 cursor-pointer'>
										Company Profiles <i className='icofont-caret-right'></i>
									</span>
								</div>
							</div> */}

							{/* <hr className='mt-16 mb-6' /> */}

							{/* <div className='px-4 py-2 w-full text-xs'>
								<h1 className='text-base font-bold'>Similar Company</h1>
								<div className='w-20 h-px bg-indigo-500 mt-2 mb-4'></div>
								
						<div className='grid gap-3'>
							<JobCard />
							<JobCard />
							<JobCard />
						</div>
							</div>

							<div className='mt-4 flex justify-end'>
								<button
									className='focus:outline-none'
									onClick={() => {
										localStorage.clear()
										window.location.href = '/'
									}}>
									Keluar <i className='icofont-sign-out'></i>
								</button>
							</div> */}
						</div>
					</div>
				</Fragment>
			)}
		</div>
	)
}

export default Detail
