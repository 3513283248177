import React, { useState, useContext, useEffect } from 'react'
import ReactModal from 'react-modal'
import Select from 'react-select'
import { AuthContext } from '../../../context/AuthContext'
import { DataContext } from '../../../context/DataContext'
import API from '../../../services/API'
import AsyncSelect from 'react-select/async'
import LocationPicker from 'react-location-picker'
import _ from 'lodash'
import Geocode from 'react-geocode'
import { toast } from 'react-toastify'

// import Input from './Input'

const EditProfileModal = ({ visible, onCancel }) => {
	const dataContext = useContext(DataContext)
	const authContext = useContext(AuthContext)
	const { userProfileEdit, setUserProfileEdit, getUserProfile, userProfile } = authContext
	const { cities } = dataContext

	const [submitting, setSubmitting] = useState(false)

	const onLogin = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.updateJobSeeker(userProfileEdit.data)
				getUserProfile()
				setSubmitting(false)
				onCancel()
				toast.success('Berhasil Ubah Data')
			} catch (error) {
				console.log(error)
				setSubmitting(false)
				toast.error('Gagal Ubah Data')
			}
		}
	}

	const [position, setPosition] = useState({ lat: 0, lng: 0 })
	const [addresArray, setAddresArray] = useState([])

	const getAddressByLatLng = async (lat, lng) => {
		try {
			const geo = await Geocode.fromLatLng(lat, lng)
			setPosition(geo.results[0].geometry.location)
			setAddresArray(
				geo.results.map(loc => ({
					value: JSON.stringify(loc.geometry.location),
					label: loc.formatted_address,
				}))
			)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (!userProfile.isLoading) {
			setPosition({ lat: parseFloat(userProfile.data.latitude), lng: parseFloat(userProfile.data.longitude) })
			getAddressByLatLng(parseFloat(userProfile.data.latitude), parseFloat(userProfile.data.longitude))
		}
	}, [userProfile.isLoading])

	const getPoint = input => {
		return new Promise(resolve => {
			if (input.length === 0) {
				resolve()
			} else {
				Geocode.fromAddress(input).then(geo => {
					resolve(
						geo.results.map(loc => ({
							value: JSON.stringify(loc.geometry.location),
							label: loc.formatted_address,
						}))
					)
				})
			}
		})
	}

	if (userProfileEdit.isLoading) {
		return null
	} else {
		return (
			<ReactModal
				className='edit-profile-modal-content'
				overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
				isOpen={visible}
				contentLabel='Minimal Modal Example'>
				<div className='flex justify-between items-center mb-3'>
					<h1 className='text-lg font-bold'>Ubah Profil</h1>
					<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
				</div>

				<hr />

				<form onSubmit={onLogin}>
					<div className='flex flex-col mt-4'>
						<label htmlFor='nameRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Nama
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='nameRegister'
								type='text'
								placeholder='Tony Stark'
								className='w-full h-full focus:outline-none'
								value={userProfileEdit.data.name}
								onChange={e =>
									setUserProfileEdit({ ...userProfileEdit, data: { ...userProfileEdit.data, name: e.target.value } })
								}
							/>
						</div>
					</div>

					<div className='flex md:justify-between items-center md:flex-row flex-col'>
						<div className='flex flex-col mt-4 w-full mr-1'>
							<label htmlFor='birthDate' className='font-semibold mb-1'>
								<span className='text-xs text-red-600'>*</span> Tempat Lahir
							</label>
							<Select
								styles={{
									control: (provided, state) => ({
										...provided,
										height: '43px',
										borderColor: '#a0aec0',
									}),
								}}
								isClearable
								value={cities.data.filter(city => city.value === parseInt(userProfileEdit.data.birthPlace))[0]}
								options={cities.data}
								// onChange={val => setRegisterForm({ ...registerForm, birthPlace: val ? val.value : '' })}
								onChange={val =>
									setUserProfileEdit({
										...userProfileEdit,
										data: { ...userProfileEdit.data, birthPlace: val ? val.value : '' },
									})
								}
							/>
						</div>

						<div className='flex flex-col mt-4 w-full ml-1'>
							<label htmlFor='birthDate' className='font-semibold mb-1'>
								<span className='text-xs text-red-600'>*</span> Tanggal Lahir
							</label>
							<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
								<input
									id='birthDate'
									type='date'
									className='w-full h-full focus:outline-none'
									value={userProfileEdit.data.birthDate}
									onChange={e =>
										setUserProfileEdit({
											...userProfileEdit,
											data: { ...userProfileEdit.data, birthDate: e.target.value },
										})
									}
								/>
							</div>
						</div>
					</div>

					<div className='flex md:justify-between items-center md:flex-row flex-col'>
						<div className='flex flex-col mt-4 w-full mr-1'>
							<label htmlFor='birthDate' className='font-semibold mb-1'>
								<span className='text-xs text-red-600'>*</span> Kota
							</label>
							<Select
								styles={{
									control: (provided, state) => ({
										...provided,
										height: '43px',
										borderColor: '#a0aec0',
									}),
								}}
								isClearable
								value={cities.data.filter(city => city.value === parseInt(userProfileEdit.data.cityID))[0]}
								options={cities.data}
								// onChange={val => setRegisterForm({ ...registerForm, birthPlace: val ? val.value : '' })}
								onChange={val =>
									setUserProfileEdit({
										...userProfileEdit,
										data: { ...userProfileEdit.data, cityID: val ? val.value : '' },
									})
								}
							/>
						</div>

						<div className='flex flex-col mt-4 w-full ml-1'>
							<label htmlFor='birthDate' className='font-semibold mb-1'>
								<span className='text-xs text-red-600'>*</span> Tanggal Lahir
							</label>
							<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
								<input
									id='phoneNumber'
									type='number'
									placeholder='08213123'
									className='w-full h-full focus:outline-none'
									value={userProfileEdit.data.phone}
									onChange={e =>
										setUserProfileEdit({ ...userProfileEdit, data: { ...userProfileEdit.data, phone: e.target.value } })
									}
								/>
							</div>
						</div>
					</div>

					<div className='flex flex-col mt-4 w-full ml-1'>
						<label htmlFor='about' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> About
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<textarea
								id='about'
								placeholder="I'am ..."
								className='w-full h-full focus:outline-none'
								value={userProfileEdit.data.about}
								onChange={e =>
									setUserProfileEdit({ ...userProfileEdit, data: { ...userProfileEdit.data, about: e.target.value } })
								}
							/>
						</div>
					</div>

					<div className='flex flex-col mt-4'>
						<label className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Pilih Point Alamat
						</label>

						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: '#a0aec0',
								}),
							}}
							onChange={options => {
								if (options) {
									let parsed = JSON.parse(options.value)
									setPosition(parsed)
									setUserProfileEdit({
										...userProfileEdit,
										data: {
											...userProfileEdit.data,
											latitude: parsed.lat,
											longitude: parsed.lng,
										},
									})
								} else {
									setPosition({ lat: userProfile.data.latitude, lng: userProfile.data.longitude })
									setUserProfileEdit({
										...userProfileEdit,
										data: {
											...userProfileEdit.data,
											latitude: userProfile.data.latitude,
											longitude: userProfile.data.longitude,
										},
									})
								}
							}}
							isClearable
							options={addresArray}
							onInputChange={_.debounce(input => {
								if (input.length === 0) {
									setAddresArray([])
								} else {
									Geocode.fromAddress(input)
										.then(geo => {
											let res = geo.results.map(loc => ({
												value: JSON.stringify(loc.geometry.location),
												label: loc.formatted_address,
											}))
											setAddresArray(res)
										})
										.catch(e => setAddresArray([]))
								}
							}, 400)}
						/>
						<small className='text-xs text-gray-600 ml-1 mt-2'>
							Perusahaan memerlukan informasi ini agar bisa menemukanmu dengan mudah.
						</small>
					</div>

					<LocationPicker
						zoom={15}
						containerElement={<div style={{ height: '100%' }}></div>}
						mapElement={<div className='h-64 rounded-md mt-4' />}
						defaultPosition={position}
						onChange={e => {
							setPosition({ lat: e.position.lat, lng: e.position.lng })
							getAddressByLatLng(e.position.lat, e.position.lng)
						}}
					/>

					<button
						type='submit'
						disabled={submitting}
						className={
							'p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black ' +
							(submitting ? 'opacity-50' : '')
						}>
						{submitting ? 'Mengirim data ...' : 'Ubah Profil'}
					</button>
				</form>
			</ReactModal>
		)
	}
}

export default EditProfileModal
