import React, { Fragment, useState } from 'react'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import API from '../services/API'
import Input from './Input'

const LoginModal = ({ visible, onCancel, onLoginCompany }) => {
	const [formValue, setFormValue] = useState({ username: '', password: '' })
	const [submitting, setSubmitting] = useState(false)
	const [errors, setErrors] = useState(null)

	const onLogin = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				const response = await API.loginUser(formValue)
				if (response.data.token) {
					localStorage.setItem('@user_token', response.data.token)
					localStorage.setItem('@role', 'JobSeeker')
					window.location.href = '/profile'
					setSubmitting(false)
					onCancel()
				}
			} catch (error) {
				console.log(error?.response?.data?.errors ?? error)
				setSubmitting(false)
				setErrors(error?.response?.data?.errors)
			}
		}
	}

	return (
		<ReactModal
			className='login-modal-content'
			overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
			isOpen={visible}
			contentLabel='Minimal Modal Example'>
			<div className='flex justify-between items-center mb-3'>
				<h1 className='text-lg font-bold'>Masuk</h1>
				<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
			</div>
			<ToastContainer />

			<hr />

			<p className='mt-3'>Selamat datang kembali !</p>

			<div className='mt-4'>
				<button className='p-2 bg-red-500 text-white font-semibold rounded-md w-full flex justify-center items-center'>
					<i className='icofont-google-plus mr-2'></i> <span> Masuk Dengan Google</span>
				</button>

				<button className='p-2 bg-blue-700 text-white font-semibold rounded-md w-full flex justify-center items-center mt-2'>
					<i className='icofont-facebook mr-2'></i> <span> Masuk Dengan Facebook</span>
				</button>
			</div>

			<div className='flex items-center my-4'>
				<div className='h-px w-full bg-gray-400'></div>
				<span className='mx-4'>atau</span>
				<div className='h-px w-full bg-gray-400'></div>
			</div>

			<form onSubmit={onLogin}>
				<Input
					name='userEmailLogin'
					label='Email'
					icon={<i className='icofont-ui-message'></i>}
					type='email'
					placeholder='hello@user.com'
					value={formValue.username}
					onChange={e => setFormValue({ ...formValue, username: e.target.value })}
				/>
				<Input
					name='userPasswordLogin'
					className='mt-3'
					label='Password'
					icon={<i className='icofont-ui-lock'></i>}
					type='password'
					placeholder='Password'
					value={formValue.password}
					onChange={e => setFormValue({ ...formValue, password: e.target.value })}
				/>

				{errors && errors.errors ? (
					<div className='my-4 bg-red-500 rounded-md w-full py-2 px-4 text-sm text-white flex justify-between items-center'>
						<p>{errors.errors}</p>

						<span className='cursor-pointer font-bold' onClick={() => setErrors(null)}>
							x
						</span>
					</div>
				) : null}

				<button
					type='submit'
					className={`p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black ${
						submitting ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'
					} `}>
					Masuk{' '}
					{submitting && (
						<Fragment>
							{' '}
							&nbsp; <i className='icofont-spinner-alt-1'></i>
						</Fragment>
					)}
				</button>

				<p className='text-center text-gray-700 text-sm'>
					Belum punya akun?{' '}
					<Link to='/register' onClick={onCancel} className='text-indigo-600'>
						Klik di sini
					</Link>{' '}
					untuk daftar dengan mudah
				</p>

				<hr className='my-2' />

				<p className='text-gray-700 text-xs'>
					Jika anda perusahaan, silahkan{' '}
					<span onClick={onLoginCompany} className='text-indigo-600 cursor-pointer'>
						Klik di sini
					</span>{' '}
				</p>
			</form>
		</ReactModal>
	)
}

export default LoginModal
