import React, { useState, useContext, useEffect } from 'react'
import ReactModal from 'react-modal'
import Select from 'react-select/creatable'
import years from '../../../utils/years'
import months from '../../../utils/months'
import { DataContext } from '../../../context/DataContext'
import MasterAPI from '../../../services/MasterAPI'
import { AuthContext } from '../../../context/AuthContext'
import { toast } from 'react-toastify'

const SkillsModal = ({ visible, onCancel, currentSkills = [] }) => {
	const dataContext = useContext(DataContext)
	const authContext = useContext(AuthContext)

	const { skills } = dataContext
	const { getUserProfile } = authContext

	const [submitting, setSubmitting] = useState(false)

	const [skillAdd, setSkilAdd] = useState([])
	const [skillDelete, setSkillDelete] = useState([])

	const onLogin = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)

			// 	if (mode === 'add') {
			try {
				await MasterAPI.assignSkillsJobSeeker(skillAdd, skillDelete)
				getUserProfile()
				setSubmitting(false)
				onCancel()
				toast.success('Sukses ubah data skill')
			} catch (error) {
				toast.error('Gagal ubah data skill')
				setSubmitting(false)
			}
			// 	}

			// 	if (mode === 'edit') {
			// 		try {
			// 			await MasterAPI.updateJobSeekerEducation(educationEdit, educationEdit?.ID)
			// 			getUserProfile()
			// 			setSubmitting(false)
			// 			onCancel()
			// 			toast.success('Sukses tambah data pendidikan')
			// 		} catch (error) {
			// 			toast.error('Gagal tambah data pendidikan')
			// 			setSubmitting(false)
			// 		}
			// 	}
		}
	}

	return (
		<ReactModal
			className='edit-education-modal-content'
			overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
			isOpen={visible}
			contentLabel='Minimal Modal Example'>
			<div className='flex justify-between items-center mb-3'>
				<h1 className='text-lg font-bold'>Edit Skill</h1>
				<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
			</div>

			<hr />

			<form onSubmit={onLogin}>
				<div className='flex flex-col mt-4'>
					<label htmlFor='schoolName' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Ubah Skill
					</label>
					<div className='w-full flex flex-wrap'>
						{currentSkills.map((skill, index) => (
							<span className='px-3 py-1 bg-gray-300 rounded-full m-1' key={index}>
								{skill.jobSeekerSkill.label}{' '}
								{skillDelete.includes(skill.ID) ? (
									<strong
										className='cursor-pointer'
										onClick={() => setSkillDelete(skillDelete.filter(del => del !== skill.ID))}>
										-
									</strong>
								) : (
									<strong className='cursor-pointer' onClick={() => setSkillDelete([...skillDelete, skill.ID])}>
										x
									</strong>
								)}
							</span>
						))}
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='maxSall' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Tambah Skills
					</label>
					<Select
						styles={{
							control: (provided, state) => ({
								...provided,
								minHeight: '43px',
								borderColor: '#a0aec0',
							}),
						}}
						isMulti
						options={skills}
						onChange={val => {
							if (val) {
								setSkilAdd(val)
							} else {
								setSkilAdd([])
							}
						}}
					/>
				</div>
				<button
					type='submit'
					className='p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black'>
					Edit Skill
				</button>
			</form>
		</ReactModal>
	)
}

export default SkillsModal
