import React, { useContext, useEffect, useState } from 'react'
import Dropdown from '../../components/DropDown'
import JobCard from '../../components/JobCard'
import LoadingState from '../../components/LoadingState'
import { JobContext } from '../../context/JobContext'
import queryString from 'query-string'
import _ from 'lodash'
import JobTypeDropDown from '../../components/JobTypeDropDown'
import { DataContext } from '../../context/DataContext'

const Job = ({ history, location }) => {
	const jobContext = useContext(JobContext)

	const dataContext = useContext(DataContext)
	const { defaultJobFunction, jobType } = dataContext

	const { jobs, getJobs, jobsParametersView, setJobsParameterView } = jobContext
	const [jobParams, setJobParams] = useState({})

	let parsedSearch = queryString.parse(location.search)

	useEffect(() => {
		if (location.search) {
			getJobs({
				...parsedSearch,
				keyword: parsedSearch?.q ?? '',
				jobFunctionID: parsedSearch?.jobFunctionID ?? '',
				jobTypeID: parsedSearch?.jobTypeID ?? '',
			})
			setJobParams({
				...parsedSearch,
				q: parsedSearch?.q ?? '',
				jobFunctionID: parsedSearch?.jobFunctionID ?? '',
				jobTypeID: parsedSearch?.jobTypeID ?? '',
			})
			setJobsParameterView({
				...jobsParametersView,
				keyword: parsedSearch?.q ?? '',
				jobFunctionID: parsedSearch?.jobFunctionID ?? '',
				jobTypeID: parsedSearch?.jobTypeID ?? '',
			})
		} else {
			getJobs({})
		}
	}, [location.search])

	const onChangeRoute = () => {
		if (Object.keys(jobParams).length > 0) {
			history.push(`/job?${queryString.stringify(jobParams)}`)
		}
	}

	useEffect(() => {
		onChangeRoute()
	}, [jobParams])

	return (
		<div className='container mx-auto '>
			<div className='lg:px-48 px-4 pb-16  mt-16'>
				<div className='flex items-center'>
					<i className='icofont-search-2 text-2xl'></i>
					<input
						value={jobsParametersView.keyword}
						className='w-full ml-8 py-2 px-4 bg-transparent border-b-2 border-gray-600 focus:outline-none'
						placeholder='Cari pekerjaan...'
						onChange={e => {
							setJobsParameterView({ ...jobsParametersView, keyword: e.target.value })
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								setJobParams({ ...jobParams, q: e.currentTarget.value })
							}
						}}
					/>
				</div>
			</div>
			<div className='p-4 w-full'>
				<ul className='flex items-center justify-start text-base text-gray-700 pt-4 lg:pt-0'>
					<li>
						<Dropdown
							onSelectSection={id => {
								getJobs({ ...parsedSearch, jobFunctionID: id })
								setJobsParameterView({ ...jobsParametersView, jobFunctionID: id })
								setJobParams({ ...jobParams, jobFunctionID: id })
							}}
						/>
					</li>
					<li>
						<JobTypeDropDown
							onSelectSection={id => {
								getJobs({ ...parsedSearch, jobTypeID: id })
								setJobsParameterView({ ...jobsParametersView, jobTypeID: id })
								setJobParams({ ...jobParams, jobTypeID: id })
							}}
						/>
					</li>
				</ul>

				<hr />
				<br />

				{location.search && (
					<div>
						<div className='flex flex-wrap w-full'>
							{parsedSearch.q && (
								<div className='px-4 py-2 bg-indigo-500 rounded-full flex items-center text-sm text-white ml-2 mt-2'>
									<span>Keyword: {parsedSearch.q}</span>
									<span
										className='text-xs ml-2 font-bold cursor-pointer'
										onClick={e => {
											e.preventDefault()
											if (parsedSearch.q) {
												delete parsedSearch.q
											}
											console.log('after', parsedSearch)
											// history.push({ ...history, location: { ...history.location, search: parsedSearch } })
											history.push({
												pathname: '/job',
												search: `?${queryString.stringify(parsedSearch)}`,
											})
											setJobsParameterView({ ...jobsParametersView, keyword: '' })
										}}>
										X
									</span>
								</div>
							)}

							{parsedSearch.jobFunctionID && (
								<div className='px-4 py-2 bg-indigo-500 rounded-full flex items-center text-sm text-white ml-2 mt-2'>
									<span>
										Fungsi Pekerjaan: &nbsp; &nbsp;
										{
											defaultJobFunction.filter(
												jobfun => parseInt(jobfun.value) === parseInt(parsedSearch.jobFunctionID)
											)[0]?.label
										}
									</span>
									<span
										className='text-xs ml-2 font-bold cursor-pointer'
										onClick={e => {
											e.preventDefault()
											if (parsedSearch.jobFunctionID) {
												delete parsedSearch.jobFunctionID
											}
											// history.push({ ...history, location: { ...history.location, search: parsedSearch } })
											history.push({
												pathname: '/job',
												search: `?${queryString.stringify(parsedSearch)}`,
											})
											setJobsParameterView({ ...jobsParametersView, jobFunctionID: '' })
										}}>
										X
									</span>
								</div>
							)}

							{parsedSearch.jobTypeID && (
								<div className='px-4 py-2 bg-indigo-500 rounded-full flex items-center text-sm text-white ml-2 mt-2'>
									<span>
										Tipe Pekerjaan: &nbsp; &nbsp;
										{jobType.filter(jobfun => parseInt(jobfun.value) === parseInt(parsedSearch.jobTypeID))[0]?.label}
									</span>
									<span
										className='text-xs ml-2 font-bold cursor-pointer'
										onClick={e => {
											e.preventDefault()
											if (parsedSearch.jobTypeID) {
												delete parsedSearch.jobTypeID
											}
											// history.push({ ...history, location: { ...history.location, search: parsedSearch } })
											history.push({
												pathname: '/job',
												search: `?${queryString.stringify(parsedSearch)}`,
											})
											setJobsParameterView({ ...jobsParametersView, jobTypeID: '' })
										}}>
										X
									</span>
								</div>
							)}
						</div>
						<br />

						<hr />
					</div>
				)}

				<br />

				{jobs.isLoading ? (
					<LoadingState />
				) : !jobs.isLoading && jobs.data.length === 0 ? (
					<div className='flex h-full py-16 w-full items-center justify-center'>
						<div>
							<img
								src={require('../../assets/images/undraw_not_found_60pq.png')}
								className={`object-contain rounded-full h-64 w-64`}
								alt='Empty'
							/>

							<h1 className='text-center text-lg font-bold mt-8'>Tidak menemukan pekerjaan :(</h1>
						</div>
					</div>
				) : (
					<div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3'>
						{jobs.data.map(job => (
							<JobCard job={job} key={job.ID} />
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default Job
