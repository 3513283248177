import React, { createContext, useEffect, useState } from 'react'
import JobAPI from '../services/JobAPI'

export const JobContext = createContext()
export const JobContextConsumer = JobContext.Consumer

const JobContextProvider = ({ children }) => {
	const [jobs, setJobs] = useState({ data: [], isLoading: true })
	const [jobsParameters, setJobsParameter] = useState({
		pageSise: 10,
		keyword: '',
		sort: 'distance',
		order: 'asc',
		distance: 30,
		publish: 1,
	})
	const [jobsParametersView, setJobsParameterView] = useState({
		pageSise: 10,
	})

	const getJobs = async (params = {}) => {
		try {
			setJobs({ ...jobs, isLoading: true })
			const response = await JobAPI.getJobs({ ...jobsParameters, ...params })
			setJobs({
				isLoading: false,
				data: response.data.list,
			})
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<JobContext.Provider
			value={{
				jobs,
				setJobs,
				jobsParameters,
				setJobsParameter,
				getJobs,
				jobsParametersView,
				setJobsParameterView,
			}}>
			{children}
		</JobContext.Provider>
	)
}

export default JobContextProvider
