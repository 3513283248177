export default [
	{
		value: 1,
		label: 'Januari',
	},
	{
		value: 2,
		label: 'Februari',
	},
	{
		value: 3,
		label: 'Maret',
	},
	{
		value: 4,
		label: 'April',
	},
	{
		value: 5,
		label: 'Mei',
	},
	{
		value: 6,
		label: 'Juni',
	},
	{
		value: 7,
		label: 'Juli',
	},
	{
		value: 8,
		label: 'Agustus',
	},
	{
		value: 9,
		label: 'September',
	},
	{
		value: 10,
		label: 'Oktober',
	},
	{
		value: 11,
		label: 'November',
	},
	{
		value: 12,
		label: 'Desember',
	},
]
