import React, { useContext, Fragment, useState, useEffect, useRef } from 'react'
import { AuthContext } from '../../context/AuthContext'
import LoadingState from '../../components/LoadingState'
import JobAPI from '../../services/JobAPI'
import JobCard from '../../components/JobCard'
import CreateJobModal from './components/CreateJobModal'
import { JobContext } from '../../context/JobContext'
import EditCompanyModal from './components/EditCompanyModal'
import { toast } from 'react-toastify'
import gravatar from 'gravatar'
import MasterAPI from '../../services/MasterAPI'
import PasswordModalCompany from './components/PasswordModalCompany'

const Profile = () => {
	const authContext = useContext(AuthContext)
	const jobContext = useContext(JobContext)
	const uploadRef = useRef(null)

	const { getJobs } = jobContext
	const { companyProfile, getCompanyProfile } = authContext

	const [jobs, setJobs] = useState({ data: [], isLoading: true })
	const [jobsArchived, setJobsArchived] = useState({ data: [], isLoading: true })
	const [jobEdit, setJobEdit] = useState(null)
	const [companyEdit, setCompanyEdit] = useState(null)
	const [modalAddVisible, setModalAddVisible] = useState(false)
	const [modalEditVisible, setModalEditVisible] = useState(false)
	const [modalEditCompanyVisible, setModalCompanyVisible] = useState(false)
	const [errorAvatar, setErrorAvatar] = useState(false)
	const [passwordModalVisible, setPasswordModalVisible] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [archived, setArchived] = useState(false)

	const fetchCompanyJobs = async () => {
		try {
			if (!companyProfile.isLoading) {
				setJobs({ ...jobs, isLoading: true })
				const response = await JobAPI.getJobs({ companyID: companyProfile.data.ID, publish: 1 })
				setJobs({
					isLoading: false,
					data: response.data.list,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchCompanyJobsArchived = async () => {
		try {
			if (!companyProfile.isLoading) {
				setJobsArchived({ ...jobsArchived, isLoading: true })
				const response = await JobAPI.getJobs({ companyID: companyProfile.data.ID, publish: 0 })
				setJobsArchived({
					isLoading: false,
					data: response.data.list,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchCompanyJobs()
		fetchCompanyJobsArchived()
	}, [companyProfile.isLoading])

	return (
		<div className='mx-auto pb-16'>
			{companyProfile.isLoading ? (
				<LoadingState />
			) : (
				<Fragment>
					{/* <ToastContainer /> */}
					<div className='bg-white pb-8 pt-16 shadow-sm'>
						<div className='container mx-auto px-6 md:max-w-5xl w-full'>
							<div className='flex'>
								<div className='flex-1 ml-6'>
									<h1 className='text-2xl font-bold'>{companyProfile.data.name}</h1>

									<div className='text-xs mt-2'>
										<i className='icofont-location-pin text-xs'></i>
										{/* <span className='job-detail-footer ml-2'>Date created:</span> */}
										<span className='ml-2'>{companyProfile.data.city.name}</span>
									</div>
									<div className='text-xs mt-2'>
										<i className='icofont-ui-user'></i>
										<span className='ml-2'>{companyProfile.data.companySize.label}</span>
									</div>
								</div>

								<div>
									<img
										onError={() => setErrorAvatar(true)}
										src={
											errorAvatar
												? gravatar.url(companyProfile.data.email, { s: 150, default: 'identicon' })
												: companyProfile.data.companyAvatar?.avatar ??
												  gravatar.url(companyProfile.data.email, { s: 150, default: 'identicon' })
										}
										alt='Company Logo'
										className='w-20 h-20 object-cover rounded-sm cursor-pointer'
										onClick={() => {
											uploadRef.current.click()
										}}
									/>
								</div>
								<input
									disabled={uploading}
									ref={uploadRef}
									type='file'
									accept='image/png,image/jpg,image/jpeg'
									name='avatarUploadCompany'
									className='hidden'
									id='avatarUploadCompany'
									onChange={e => {
										if (!uploading) {
											setUploading(true)
											MasterAPI.updateCompanyAvatar({ avatar: e.target.files[0] })
												.then(() => {
													getCompanyProfile()
													toast.success('Berhasil ubah avatar')
													setUploading(false)
												})
												.catch(() => {
													toast.error('Gagal upload avatar')
												})
										}
									}}
								/>
							</div>
						</div>
					</div>

					<div className='container flex flex-col md:flex-row mx-auto md:px-2 px-6 md:max-w-5xl w-full mt-6'>
						<div className='flex-1 pr-2'>
							<div className='bg-white border rounded-md p-6'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Tentang Perusahaan</h1>
									<button
										className='border-none bg-transparent focus:outline-none'
										onClick={() => {
											setModalCompanyVisible(true)
											setCompanyEdit(companyProfile.data)
										}}>
										<i className='icofont-ui-edit'></i>
									</button>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								<div className='text-sm jobdesk-container'>
									<p>{companyProfile.data.description}</p>
								</div>
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Pekerjaan</h1>
									<button
										className='border-none bg-transparent focus:outline-none'
										onClick={() => setModalAddVisible(true)}>
										<i className='icofont-plus'></i>
									</button>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								{jobs.isLoading ? (
									<div className='w-full text-center text-sm'>
										<img
											src={require('../../assets/images/undraw_Steps_re_odoy.png')}
											className='h-32 w-32 mx-auto'
											alt='Loading'
										/>
										<h1>Loading ... </h1>
									</div>
								) : !jobs.isLoading && jobs.data.length === 0 ? (
									<div className='w-full text-center text-sm'>
										<img
											src={require('../../assets/images/empty_data.png')}
											className='h-32 w-32 mx-auto'
											alt='Empty'
										/>
										<h1>Belum ada pekerjaan ... </h1>
									</div>
								) : (
									<div className='text-sm jobdesk-container'>
										<div className='grid md:grid-cols-2 gap-3'>
											{jobs.data.map(job => (
												<JobCard
													key={job.ID}
													job={job}
													editable
													onEdit={() => {
														setJobEdit(job)
														setModalEditVisible(true)
													}}
												/>
											))}
										</div>
									</div>
								)}
							</div>

							{jobsArchived.isLoading ? null : !jobsArchived.isLoading && jobsArchived.data.length === 0 ? null : (
								<div className='bg-white border rounded-md p-6 mt-4'>
									<div className='flex justify-between'>
										<h1 className='font-bold text-xl'>Arsip Pekerjaan</h1>
										<button
											className='border-none bg-transparent focus:outline-none'
											onClick={() => {
												setModalAddVisible(true)
												setArchived(true)
											}}>
											<i className='icofont-plus'></i>
										</button>
									</div>
									<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

									<div className='text-sm jobdesk-container'>
										<div className='grid md:grid-cols-2 gap-3'>
											{jobsArchived.data.map(job => (
												<JobCard
													key={job.ID}
													job={job}
													editable
													onEdit={() => {
														setJobEdit(job)
														setModalEditVisible(true)
													}}
												/>
											))}
										</div>
									</div>
								</div>
							)}
						</div>

						<div className='bg-transparent w-full md:overflow-auto md:w-1/4 md:ml-2 min-h-0 text-gray-800 pb-8 mx-auto px-1 mt-4 md:px-0 md:mt-0'>
							<div className='bg-white p-4 border rounded-md'>
								<h1 className='text-base font-bold mb-4'>Lainnya</h1>
								<div className='w-20 h-px bg-indigo-500 mt-2 mb-4'></div>
								<div className='grid gap-3 grid-cols-2'>
									<button
										className='py-2 rounded-sm bg-gray-300 text-xs focus:outline-none'
										onClick={() => setPasswordModalVisible(true)}>
										Ubah Password
									</button>
									<button
										className='focus:outline-none'
										onClick={() => {
											localStorage.clear()
											window.location.href = '/'
										}}>
										Keluar <i className='icofont-sign-out'></i>
									</button>
								</div>
							</div>
						</div>
					</div>
					<CreateJobModal
						visible={modalAddVisible}
						onCancel={() => setModalAddVisible(false)}
						onSucces={() => {
							fetchCompanyJobs()
							fetchCompanyJobsArchived()
							getJobs()
						}}
						archived={archived}
					/>

					<CreateJobModal
						visible={modalEditVisible}
						onCancel={() => {
							setModalEditVisible(false)
							// setJobEdit(null)
							setArchived(false)
						}}
						onSucces={() => {
							fetchCompanyJobs()
							fetchCompanyJobsArchived()
							getJobs()
							setArchived(false)
						}}
						mode='edit'
						jobEdit={jobEdit}
						setJobEdit={setJobEdit}
						archived={archived}
					/>

					<EditCompanyModal
						visible={modalEditCompanyVisible}
						companyEdit={companyEdit}
						onCancel={() => {
							setModalCompanyVisible(false)
							setCompanyEdit(null)
						}}
						setCompanyEdit={setCompanyEdit}
						onSucces={() => {
							getCompanyProfile()
							setModalCompanyVisible(false)
							setCompanyEdit(null)
							toast.success('Sukses ubah profil')
						}}
					/>

					<PasswordModalCompany
						visible={passwordModalVisible}
						onCancel={() => setPasswordModalVisible(false)}
						onSuccess={() => getCompanyProfile()}
					/>
				</Fragment>
			)}
		</div>
	)
}

export default Profile
