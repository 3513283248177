import React from 'react'
import moment from 'moment'

const WorkHistoryCard = ({ showEdit, onEdit, experience }) => {
	return (
		<div className='flex rounded-md border p-2 relative bg-white'>
			{showEdit && (
				<span className='absolute right-0 top-0 p-2'>
					<i className='icofont-ui-edit text-xs cursor-pointer' onClick={onEdit}></i>
				</span>
			)}

			<div>
				<img
					src='https://cdn.pixabay.com/photo/2019/12/14/07/21/building-4694350_1280.png'
					alt='DOOgether'
					className='h-12 w-12 object-cover rounded-sm'
				/>
			</div>

			<div className='px-6'>
				<h1 className='text-sm font-bold'>{experience?.title}</h1>
				<div className='text-xs'>
					<span>{experience?.companyName}</span>
				</div>
				<div className='text-xs mt-2'>
					<span className='job-detail-footer'>
						{experience?.startDate && moment(experience?.startDate, 'YYYY-MM').format('MMM YYYY')}{' '}
						{experience.endDate && ' - ' + moment(experience.endDate, 'YYYY-MM').format('MMM YYYY')}
					</span>
					<span>3mo</span>
				</div>
				{/* <div className='text-xs'>
					<span>Depok, Indonesia</span>
				</div> */}
			</div>
		</div>
	)
}

export default WorkHistoryCard
