import React, { useContext } from 'react'
import Popper from 'popper.js'
import { DataContext } from '../context/DataContext'

const distanceOption = [
	{
		value: { distanceStart: 10, distanceEnd: 30 },
		label: '10-30',
	},
	{
		value: { distanceStart: 30, distanceEnd: 50 },
		label: '30-50',
	},
	{
		value: { distanceStart: 50, distanceEnd: 100 },
		label: '50-100',
	},
]

const DropDownDistanceJobSeeker = ({ color = 'white', onSelectSection }) => {
	const dataContext = useContext(DataContext)
	const { defaultJobFunction } = dataContext

	// dropdown props
	const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false)
	const btnDropdownRef = React.createRef()
	const popoverDropdownRef = React.createRef()
	const openDropdownPopover = e => {
		new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
			placement: 'bottom-start',
		})
		setDropdownPopoverShow(true)
		e.stopPropagation()
	}
	const closeDropdownPopover = e => {
		setDropdownPopoverShow(false)
		e.stopPropagation()
	}
	// bg colors
	let bgColor
	color === 'white' ? (bgColor = 'bg-gray-800') : (bgColor = 'bg-' + color + '-500')
	return (
		<>
			<div className='flex flex-wrap'>
				<div className='w-full px-4'>
					<div className='relative inline-flex align-middle w-full'>
						<span
							className='lg:p-4 py-3 px-0 flex items-center border-b-4 border-transparent transition duration-500 hover:border-indigo-400 cursor-pointer'
							ref={btnDropdownRef}
							onClick={e => {
								dropdownPopoverShow ? closeDropdownPopover(e) : openDropdownPopover(e)
							}}>
							<span>Jarak</span> <i className='icofont-thin-down ml-2'></i>
						</span>
						<div
							ref={popoverDropdownRef}
							className={
								(dropdownPopoverShow ? 'block ' : 'hidden ') +
								(color === 'white' ? 'bg-white ' : bgColor + ' ') +
								'text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 drop-down-wrapper'
							}
							style={{ width: '16rem' }}>
							<div className='flex px-2 items-center justify-end'>
								<i className='icofont-close-line cursor-pointer' onClick={e => closeDropdownPopover(e)}></i>
							</div>

							{distanceOption.map((distance, index) => (
								<div className='flex px-2 mb-2 items-center'>
									<input
										type='radio'
										name='location'
										id={`check-${index}`}
										onClick={e => {
											onSelectSection(distance.value)
											closeDropdownPopover(e)
										}}
									/>
									<label
										htmlFor={`check-${index}`}
										className={
											'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent ' +
											(color === 'white' ? ' text-gray-800' : 'text-white')
										}>
										{distance.label}
									</label>
								</div>
							))}

							{/* {defaultJobFunction.map(jobFunc => (
								<div key={jobFunc.value} className='flex px-2 mb-2 items-center'>
									<input type='checkbox' id={`check-${jobFunc.key}`} />

									<label
										htmlFor={`check-${jobFunc.key}`}
										className={
											'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent ' +
											(color === 'white' ? ' text-gray-800' : 'text-white')
										}
										// onClick={e => {
										// 	e.preventDefault()
										// 	onSelectSection(jobFunc.value)
										// 	closeDropdownPopover(e)
										// }}
									>
										{jobFunc.label}
									</label>
								</div>
							))} */}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default DropDownDistanceJobSeeker
