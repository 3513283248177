import React from 'react'

const LoadingState = ({ size = 'regular' }) => {
	return (
		<div className='flex h-full py-16 w-full items-center justify-center'>
			<div>
				<img
					src={require('../assets/images/undraw_Steps_re_odoy.png')}
					className={`object-cover rounded-full ` + (size === 'small' ? 'h-32 w-32' : 'h-64 w-64')}
					alt='Loading'
				/>

				<h1 className='text-center text-lg font-bold mt-8'>Loading</h1>
			</div>
		</div>
	)
}

export default LoadingState
