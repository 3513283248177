import React, { useState, Fragment, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LoginModal from './LoginModal'
import LoginModalCompany from './LoginModalCompany'
import gravatar from 'gravatar'

const TOKEN = localStorage.getItem('@user_token')
const IS_COMPANY = localStorage.getItem('@role') && localStorage.getItem('@role') === 'Company'
const IS_JOBSEEKER = localStorage.getItem('@role') && localStorage.getItem('@role') === 'JobSeeker'

const Header = () => {
	const authContext = useContext(AuthContext)

	const { userProfile, companyProfile } = authContext

	const [showModal, setShowModal] = useState(false)
	const [showModalCompany, setShowModalCompany] = useState(false)
	const [avatarError, setAvatarError] = useState(false)

	return (
		<div className='w-full bg-white px-6'>
			<header className='container mx-auto  flex flex-wrap items-center lg:py-0 py-2'>
				<div className='flex-1 flex justify-between items-center'>
					<Link to='/'>
						<img src={require('../assets/images/logo.png')} alt='Bursa Kerja' className='h-8 w-8 object-contain' />
					</Link>
				</div>
				<label htmlFor='menu-toggle' className='cursor-pointer lg:hidden block'>
					<i className='icofont-navigation-menu'></i>
				</label>
				<input className='hidden' type='checkbox' id='menu-toggle' />
				<div className='hidden lg:flex lg:items-center lg:w-auto w-full' id='menu'>
					<nav>
						<ul className='lg:flex items-center justify-between text-base text-gray-700 pt-4 lg:pt-0'>
							<li>
								<NavLink
									activeClassName='border-indigo-400'
									className='lg:p-4 py-3 px-0 block border-b-4 border-transparent transition duration-500 hover:border-indigo-400'
									to='/job'>
									Pekerjaan
								</NavLink>
							</li>
							<li>
								<NavLink
									activeClassName='border-indigo-400'
									className='lg:p-4 py-3 px-0 block border-b-4 border-transparent transition duration-500 hover:border-indigo-400'
									to='/company'>
									Perusahaan
								</NavLink>
							</li>
							{IS_COMPANY && (
								<li className='md: mr-8'>
									<NavLink
										activeClassName='border-indigo-400'
										className='lg:p-4 py-3 px-0 block border-b-4 border-transparent transition duration-500 hover:border-indigo-400'
										to='/job-seeker'>
										Pelamar
									</NavLink>
								</li>
							)}
							{!TOKEN && (
								<Fragment>
									<li>
										<span
											onClick={() => setShowModal(true)}
											className='lg:p-4 py-3 px-0 block border-b-4 border-transparent transition duration-500 hover:border-indigo-400 cursor-pointer'>
											Masuk
										</span>
									</li>

									<li>
										<NavLink
											activeClassName='border-indigo-400'
											className='lg:p-4 py-3 px-0 block border-b-4 border-transparent transition duration-500 hover:border-indigo-400'
											to='/register'>
											Daftar
										</NavLink>
									</li>
								</Fragment>
							)}
						</ul>
					</nav>
					{TOKEN && (
						<Fragment>
							{IS_COMPANY && (
								<NavLink
									to='/profile-company'
									activeClassName='rounded-full border-indigo-400'
									className='lg:ml-4 flex items-center justify-start lg:mb-0 mb-4 pointer-cursor rounded-full w-10 h-10 border-2 object-cover border-transparent hover:border-indigo-400 transition duration-150'>
									<img
										onError={() => setAvatarError(true)}
										className='rounded-full w-full h-full border-2 object-cover border-transparent'
										src={
											companyProfile.isLoading
												? 'https://cdn.pixabay.com/photo/2019/12/14/07/21/building-4694350_1280.png'
												: avatarError
												? gravatar.url(companyProfile.data.email, { s: 50, default: 'identicon' })
												: companyProfile.data.companyAvatar?.avatar ??
												  gravatar.url(companyProfile.data.email, { s: 50, default: 'identicon' })
										}
										alt='Company'
									/>
								</NavLink>
							)}

							{IS_JOBSEEKER && (
								<NavLink
									to='/profile'
									activeClassName='rounded-full border-indigo-400'
									className='lg:ml-4 flex items-center justify-start lg:mb-0 mb-4 pointer-cursor rounded-full w-10 h-10 border-2 object-cover border-transparent hover:border-indigo-400 transition duration-150'>
									<img
										onError={() => setAvatarError(true)}
										className='rounded-full w-full h-full border-2 object-cover border-transparent'
										src={
											userProfile.isLoading
												? require('../assets/images/user.png')
												: avatarError
												? gravatar.url(userProfile.data.email, { s: 50, default: 'identicon' })
												: userProfile.data.jobSeekerAvatar?.avatar ??
												  gravatar.url(userProfile.data.email, { s: 50, default: 'identicon' })
										}
										alt='Jobseeker'
									/>
								</NavLink>
							)}

							<button
								className='block lg:hidden focus:outline-none'
								onClick={() => {
									localStorage.clear()
									window.location.href = '/'
								}}>
								Keluar <i className='icofont-sign-out'></i>
							</button>
						</Fragment>
					)}
				</div>

				<LoginModal
					visible={showModal}
					onCancel={() => setShowModal(false)}
					onLoginCompany={() => {
						setShowModal(false)
						setShowModalCompany(true)
					}}
				/>
				<LoginModalCompany
					visible={showModalCompany}
					onCancel={() => setShowModalCompany(false)}
					onLoginJobSeeker={() => {
						setShowModal(true)
						setShowModalCompany(false)
					}}
				/>
			</header>
		</div>
	)
}

export default Header
