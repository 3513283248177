import React, { createContext, useEffect, useState } from 'react'
import API from '../services/API'
import MasterAPI from '../services/MasterAPI'

export const AuthContext = createContext()
export const AuthContextConsumer = AuthContext.Consumer

const AuthContextProvider = ({ children }) => {
	const [submitting, setSubmitting] = useState(false)
	const [userProfile, setUserProfile] = useState({ data: null, isLoading: true })
	const [userProfileEdit, setUserProfileEdit] = useState({ data: null, isLoading: true })
	const [companyProfile, setCompanyProfile] = useState({ data: null, isLoading: true })
	const [hasValidNIK, setHasValidNIK] = useState(false)
	const [validNIKData, setValidNIKData] = useState({})
	const [hasValidMOU, setHasValidMOU] = useState(false)
	const [validMOUData, setValidMOUData] = useState({})
	// const [companyJob]

	const [is401, setIs401] = useState(false)

	const [registerForm, setRegisterForm] = useState({
		name: '',
		email: '',
		password: '',
		confirmedPassword: '',
		phone: '',
		birthDate: '',
		birthPlace: '',
		cityID: '',
		latitude: 0,
		longitude: 0,
	})
	const [errorRegister, setErrorRegister] = useState(null)

	const onRegister = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				if (is401) {
					setIs401(false)
				}
				const response = await API.register(registerForm)
				if (response.data.token) {
					setSubmitting(false)
					setRegisterForm({
						name: '',
						email: '',
						password: '',
						confirmedPassword: '',
						phone: '',
						birthDate: '',
						birthPlace: '',
					})

					localStorage.setItem('@user_token', response.data.token)
					localStorage.setItem('@role', 'JobSeeker')
					window.location.href = '/profile'
				}
			} catch (error) {
				setErrorRegister(error?.response?.data?.errors ?? error)
				if (error.response.status === 401) {
					setIs401(true)
				}
				setSubmitting(false)
			}
		}
	}

	const getUserProfile = async () => {
		try {
			const account = await API.checkAccount()
			if (account.data.jobSeekerAccess.jobSeekerID) {
				const response = await API.userProfile(account.data.jobSeekerAccess.jobSeekerID)
				setUserProfile({
					isLoading: false,
					data: response.data,
				})
				setUserProfileEdit({
					isLoading: false,
					data: response.data,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const getCompanyProfile = async () => {
		try {
			const account = await API.checkAccount()
			if (account.data.companyAccess.companyID) {
				const response = await MasterAPI.detailCompany(account.data.companyAccess.companyID)
				setCompanyProfile({
					isLoading: false,
					data: response.data,
				})
				// setUserProfileEdit({
				// 	isLoading: false,
				// 	data: response.data,
				// })
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getUserProfile()
		getCompanyProfile()
	}, [])

	return (
		<AuthContext.Provider
			value={{
				registerForm,
				setRegisterForm,
				onRegister,
				errorRegister,
				setErrorRegister,
				submitting,
				setSubmitting,
				userProfile,
				setUserProfile,
				userProfileEdit,
				setUserProfileEdit,
				getUserProfile,
				is401,
				setIs401,
				companyProfile,
				setCompanyProfile,
				getCompanyProfile,
				hasValidNIK,
				setHasValidNIK,
				validNIKData,
				setValidNIKData,
				hasValidMOU,
				setHasValidMOU,
				validMOUData,
				setValidMOUData,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContextProvider
