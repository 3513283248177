import React from 'react'
import moment from 'moment'

const CertificateCard = ({ showEdit = false, onEdit, certificate }) => {
	return (
		<div className='flex rounded-md border p-2 relative'>
			{showEdit && (
				<span className='absolute right-0 top-0 p-2 cursor-pointer' onClick={onEdit}>
					<i className='icofont-ui-edit text-xs'></i>
				</span>
			)}

			<div>
				<img
					src='https://www.kemdikbud.go.id/main/files/large/33ddc3bc2640689'
					alt='Certificate'
					className='h-12 w-12 object-cover rounded-sm'
				/>
			</div>

			<div className='px-6'>
				<h1 className='text-sm font-semibold'>{certificate.name}</h1>
				<h2 className='text-xs'>{certificate.issuer}</h2>
				<div className='text-xs mt-2'>
					<i className='icofont-clock-time'></i>
					{certificate.issuingDate && (
						<span className='job-detail-footer ml-2'>
							Published: {moment(certificate.issuingDate, 'YYYY-MM-DD').format('MMM DD')}
						</span>
					)}

					{certificate.expired && <span>Published: {moment(certificate.expired, 'YYYY-MM-DD').format('MMM DD')}</span>}
				</div>
				<h2 className='text-xs text-gray-600'>{certificate?.credentialID}</h2>
			</div>
		</div>
	)
}

export default CertificateCard
