import Axios from '../utils/axios'
import queryString from 'query-string'

export default class MasterAPI {
	static getCity(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/city?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getSkills(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			const response = await Axios.get(
				`/mitra-vokasi/job-seeker/data/job-seeker-skill?${queryString.stringify(parameters)}`
			)

			if (response.data.data.list) {
				const result = response.data.data.list.map(skill => ({
					value: skill.label,
					label: skill.label,
				}))
				resolve(result)
			}

			if (response.data.data.list.length === 0) {
				reject()
			}
		})
	}

	static getEducationTier(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/education-tier?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static listCompanySize(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/company/size?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static detailCompany(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/company/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateCompany(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post(`/mitra-vokasi/company/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static listCompany(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/company?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static listJobSeeker(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/job-seeker?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateJobSeekerEducation(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/job-seeker/education/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static updateJobSeekerAvatar(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/job-seeker/avatar`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static updateCompanyAvatar(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/company/avatar`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addJobSeekerEducation(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/mitra-vokasi/job-seeker/education', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addJobSeekerExperiencs(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/mitra-vokasi/job-seeker/experience', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addJobSeekerSertificate(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/mitra-vokasi/job-seeker/license-certificate', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateJobSeekerSertificateEdit(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/job-seeker/license-certificate/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateJobSeekerExperiencs(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/job-seeker/experience/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static registerCompany(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/mitra-vokasi/company', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static assignSkillsJobSeeker(skillsAdd = [], skillsRemove = []) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				skillsAdd.forEach(skill => {
					formData.append('creates[]', skill.value)
				})

				skillsRemove.forEach(skill => {
					formData.append('removes[]', skill)
				})

				const response = await Axios.post(`/mitra-vokasi/job-seeker/assign-skill`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
