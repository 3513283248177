import React from 'react'
import ReactDOM from 'react-dom'
import './assets/main.css'
import './assets/style.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import AuthContextProvider from './context/AuthContext'
import DataContextProvider from './context/DataContext'
import JobContextProvider from './context/JobContext'

ReactDOM.render(
	<React.StrictMode>
		<AuthContextProvider>
			<DataContextProvider>
				<JobContextProvider>
					<App />
				</JobContextProvider>
			</DataContextProvider>
		</AuthContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
