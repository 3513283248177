import React, { useState, useContext, useEffect } from 'react'
import ReactModal from 'react-modal'
import Select from 'react-select'
import years from '../../../utils/years'
import months from '../../../utils/months'
import { DataContext } from '../../../context/DataContext'
import MasterAPI from '../../../services/MasterAPI'
import { AuthContext } from '../../../context/AuthContext'
import { toast } from 'react-toastify'

const JobModal = ({ visible, onCancel, mode = 'add', jobEdit, setJobEdit }) => {
	const dataContext = useContext(DataContext)
	const authContext = useContext(AuthContext)

	const { educationTier } = dataContext
	const { getUserProfile } = authContext

	const [submitting, setSubmitting] = useState(false)
	const [formValue, setFormValue] = useState({
		title: '',
		companyName: '',
		address: '',
		startDate: '',
		endDate: '',
		description: '',
	})

	const onLogin = async e => {
		e.preventDefault()
		// if (!submitting) {
		// 	setSubmitting(true)

		if (mode === 'add') {
			try {
				await MasterAPI.addJobSeekerExperiencs(formValue)
				getUserProfile()
				setSubmitting(false)
				onCancel()
				toast.success('Sukses tambah data pengalaman kerja')
			} catch (error) {
				toast.error('Gagal tambah data pengalaman kerja')
				setSubmitting(false)
			}
		}

		if (mode === 'edit') {
			try {
				await MasterAPI.updateJobSeekerExperiencs(jobEdit, jobEdit?.ID)
				getUserProfile()
				setSubmitting(false)
				onCancel()
				toast.success('Sukses tambah data pengalaman kerja')
			} catch (error) {
				toast.error('Gagal tambah data pengalaman kerja')

				setSubmitting(false)
			}
		}
	}

	return (
		<ReactModal
			className='edit-education-modal-content'
			overlayClassName='fixed top-0 bottom-0 left-0 right-0 login-modal '
			isOpen={visible}
			contentLabel='Minimal Modal Example'>
			<div className='flex justify-between items-center mb-3'>
				<h1 className='text-lg font-bold'>{mode === 'add' ? 'Tambah' : 'Ubah'} Pengalaman Kerja</h1>
				<i className='icofont-close-line text-base cursor-pointer' onClick={onCancel}></i>
			</div>

			<hr />

			<form onSubmit={onLogin}>
				<div className='flex flex-col mt-4'>
					<label htmlFor='jobTitle' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Posisi Pekerjaan
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='jobTitle'
							type='text'
							placeholder='Developer'
							className='w-full h-full focus:outline-none'
							value={mode === 'add' ? formValue.title : jobEdit?.title}
							onChange={e => {
								if (mode === 'add') {
									setFormValue({ ...formValue, title: e.target.value })
								} else {
									setJobEdit({ ...jobEdit, title: e.target.value })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='companyName' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Nama Perusahaan
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='companyName'
							type='text'
							placeholder='PT. Generasi Muda'
							className='w-full h-full focus:outline-none'
							value={mode === 'add' ? formValue.companyName : jobEdit?.companyName}
							onChange={e => {
								if (mode === 'add') {
									setFormValue({ ...formValue, companyName: e.target.value })
								} else {
									setJobEdit({ ...jobEdit, companyName: e.target.value })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex justify-between items-center'>
					<div className='flex flex-col mt-4 mr-1 w-full'>
						<label htmlFor='startDate' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tanggal Masuk
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='startDate'
								type='month'
								className='w-full h-full focus:outline-none'
								value={mode === 'add' ? formValue.startDate : jobEdit?.startDate}
								onChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, startDate: e.target.value })
									} else {
										setJobEdit({ ...jobEdit, startDate: e.target.value })
									}
								}}
							/>
						</div>
					</div>

					<div className='flex flex-col mt-4 ml-1 w-full'>
						<label htmlFor='endDate' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tanggal Keluar
						</label>
						<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
							<input
								id='endDate'
								type='month'
								className='w-full h-full focus:outline-none'
								value={mode === 'add' ? formValue.endDate : jobEdit?.endDate}
								onChange={e => {
									if (mode === 'add') {
										setFormValue({ ...formValue, endDate: e.target.value })
									} else {
										setJobEdit({ ...jobEdit, endDate: e.target.value })
									}
								}}
							/>
						</div>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='address' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Alamat Tempat Kerja
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='address'
							type='text'
							placeholder='Jl. A. Yani'
							className='w-full h-full focus:outline-none'
							value={mode === 'add' ? formValue.address : jobEdit?.address}
							onChange={e => {
								if (mode === 'add') {
									setFormValue({ ...formValue, address: e.target.value })
								} else {
									setJobEdit({ ...jobEdit, address: e.target.value })
								}
							}}
						/>
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label htmlFor='description' className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Deskripsi Pekerjaan
					</label>
					<div className={'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 border-gray-500'}>
						<input
							id='description'
							type='text'
							placeholder='Developer'
							className='w-full h-full focus:outline-none'
							value={mode === 'add' ? formValue.description : jobEdit?.description}
							onChange={e => {
								if (mode === 'add') {
									setFormValue({ ...formValue, description: e.target.value })
								} else {
									setJobEdit({ ...jobEdit, description: e.target.value })
								}
							}}
						/>
					</div>
				</div>

				<button
					type='submit'
					className='p-2 bg-yellow-500 font-semibold rounded-md w-full flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black'>
					{mode === 'add' ? 'Tambah' : 'Ubah'} Pengalaman Kerja
				</button>
			</form>
		</ReactModal>
	)
}

export default JobModal
