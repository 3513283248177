import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import gravatar from 'gravatar'

const JobCard = ({ job, editable = false, onEdit = () => null }) => {
	const [errorAvatar, setErrorAvatar] = useState(false)

	return (
		<div className='border border-gray-400 bg-white flex flex-col justify-between rounded-md p-2 text-gray-800 hover:-translate-y-1 transform jobcard transition duration-150 relative'>
			{editable && (
				<div className='absolute top-0 right-0 p-2 text-xs cursor-pointer'>
					<i onClick={onEdit} className='icofont-ui-edit'></i>
				</div>
			)}

			<div className='flex justify-between'>
				<Link to={`/job/${job.ID}`}>
					<img
						onError={() => setErrorAvatar(true)}
						src={
							errorAvatar
								? gravatar.url(job.company.email, { s: 50, default: 'identicon' })
								: job.company.companyAvatar?.avatar ?? gravatar.url(job.company.email, { s: 50, default: 'identicon' })
						}
						alt='Company Logo'
						className='w-16 h-16 object-cover rounded-md'
					/>
				</Link>
			</div>

			<div className=' flex flex-col justify-between flex-1 mt-2'>
				<div>
					<Link to={`/job/${job.ID}`}>
						<h1 className='text-sm font-bold'>{job.title}</h1>
					</Link>

					<p className='text-xs  text-blue-700'>{job.company.name}</p>
				</div>

				<div className='pt-1'>
					{/* <p className='text-sm'>asdadad</p> */}
					<p className='text-sm'>{job.company.name}</p>
				</div>

				{job.distance !== null && (
					<div className='pt-1'>
						{/* <p className='text-sm'>asdadad</p> */}
						<p className='text-xs'>
							<i className='icofont-location-arrow'></i> {job?.distance ?? '-'} Km
						</p>
						<p className='text-xs italic'>*jarak yang ditampilkan adalah jarak garis lurus</p>
					</div>
				)}

				<div className='pt-2'>
					<div className='w-6 h-px bg-gray-500 mb-2'></div>
					<p className='text-xs'>
						<i className='icofont-clock-time'></i> {moment(job.created, 'YYYY-MM-DD').format('DD MMMM YYYY')}
					</p>
				</div>
			</div>
		</div>
	)
}

export default JobCard
