import React from 'react'
import Cards from './components/Cards'

const Home = () => {
	return (
		<div>
			<div className='bg-gray-800 jumbotron'>
				<img src={require('../../assets/images/main_bg.jpg')} alt='main bg' className='w-full h-full object-cover' />
			</div>

			<Cards />
		</div>
	)
}

export default Home
