import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import LoginModal from '../../components/LoginModal'
import { DataContext } from '../../context/DataContext'
import _ from 'lodash'
import { AuthContext } from '../../context/AuthContext'
import LocationPicker from 'react-location-picker'
import Geocode from 'react-geocode'
import { Link, Redirect } from 'react-router-dom'
import LoginModalCompany from '../../components/LoginModalCompany'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API)

const Register = () => {
	const dataContext = useContext(DataContext)
	const authContext = useContext(AuthContext)

	const { cities } = dataContext
	const {
		registerForm,
		setRegisterForm,
		onRegister,
		errorRegister,
		submitting,
		is401,
		setIs401,
		hasValidNIK,
		validNIKData,
	} = authContext

	const [modalLoginVisible, setModalLoginVisible] = useState(false)
	const [modalLoginVisibleCompany, setModalLoginVisibleCompany] = useState(false)

	const [isAgree, setIsAgree] = useState(false)
	const [position, setPosition] = useState({ lat: 0, lng: 0 })
	const [addresArray, setAddresArray] = useState([])

	const getAddressByLatLng = async (lat, lng) => {
		try {
			const geo = await Geocode.fromLatLng(lat, lng)
			setPosition(geo.results[0].geometry.location)
			setAddresArray(
				geo.results.map(loc => ({
					value: JSON.stringify(loc.geometry.location),
					label: loc.formatted_address,
				}))
			)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				position => {
					setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
					getAddressByLatLng(position.coords.latitude, position.coords.longitude)
				},
				error => {
					setPosition({ lat: -6.1753871, lng: 106.8249641 })
					getAddressByLatLng(-6.1753871, 106.8249641)
				}
			)
		} else {
			console.log('Not Available')
			setPosition({ lat: -6.1753871, lng: 106.8249641 })
			getAddressByLatLng(-6.1753871, 106.8249641)
		}
	}, [])

	useEffect(() => {
		if (hasValidNIK && validNIKData) {
			setRegisterForm({
				...registerForm,
				name: validNIKData.name,
				birthDate: validNIKData.bornDate,
				birthPlace: validNIKData.bornPlace,
			})
		}
	}, [hasValidNIK, validNIKData])

	if (!hasValidNIK) {
		return <Redirect to='/register/check' />
	}

	return (
		<div className='container mx-auto'>
			<div className='text-center mt-16'>
				<h1 className='font-bold text-4xl'>Silahkan Buat</h1>
				<h1 className='font-bold text-4xl'>Profil Anda</h1>
			</div>
			<div className='register-container mx-auto mt-16 bg-white py-6 px-12 rounded-md shadow-md'>
				<div className='grid md:grid-cols-2 gap-6'>
					{/* {input start} */}
					<div className='flex flex-col'>
						<label htmlFor='nameRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Nama
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.name ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='nameRegister'
								type='text'
								placeholder='Tony Stark'
								className='w-full h-full focus:outline-none'
								value={registerForm.name}
								onChange={e => setRegisterForm({ ...registerForm, name: e.target.value })}
							/>
						</div>
						{errorRegister ? (
							errorRegister.name ? (
								<span className='text-xs text-red-600'>{errorRegister.name[0]}</span>
							) : null
						) : null}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='userEmailregister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Email
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.email ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userEmailregister'
								type='email'
								placeholder='user@gmail.com'
								className='w-full h-full focus:outline-none'
								value={registerForm.email}
								onChange={e => setRegisterForm({ ...registerForm, email: e.target.value })}
							/>
						</div>
						{errorRegister ? (
							errorRegister.email ? (
								<span className='text-xs text-red-600'>{errorRegister.email[0]}</span>
							) : null
						) : null}
					</div>

					<div className='flex flex-col'>
						<label className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tempat Lahir
						</label>
						<Select
							autoFocus={false}
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: errorRegister && errorRegister.birthPlace ? '#e53e3e' : '#a0aec0',
								}),
							}}
							isClearable
							isLoading={cities.isLoading}
							options={cities.data}
							value={cities.data.filter(city => city.value === parseInt(registerForm.birthPlace))[0]}
							onChange={val => setRegisterForm({ ...registerForm, birthPlace: val ? val.value : '' })}
						/>
						{errorRegister ? (
							errorRegister.birthPlace ? (
								<span className='text-xs text-red-600'>{errorRegister.birthPlace[0]}</span>
							) : null
						) : null}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='bornDateRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tanggal Lahir
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.birthDate ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='bornDateRegister'
								type='date'
								className='w-full h-full focus:outline-none'
								value={registerForm.birthDate}
								onChange={e => setRegisterForm({ ...registerForm, birthDate: e.target.value })}
							/>
						</div>
						{errorRegister ? (
							errorRegister.birthDate ? (
								<span className='text-xs text-red-600'>{errorRegister.birthDate[0]}</span>
							) : null
						) : null}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='userPasswordRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Password
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.password ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userPasswordRegister'
								type='password'
								placeholder='Password'
								className='w-full h-full focus:outline-none'
								value={registerForm.password}
								onChange={e => setRegisterForm({ ...registerForm, password: e.target.value })}
							/>
						</div>
						{errorRegister ? (
							errorRegister.password ? (
								<span className='text-xs text-red-600'>{errorRegister.password[0]}</span>
							) : null
						) : null}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='userPasswordRegister1' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Konfirmasi Password
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.password ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userPasswordRegister1'
								type='password'
								placeholder='Password'
								className='w-full h-full focus:outline-none'
								value={registerForm.confirmedPassword}
								onChange={e => setRegisterForm({ ...registerForm, confirmedPassword: e.target.value })}
							/>
						</div>
						{errorRegister ? (
							errorRegister.password ? (
								<span className='text-xs text-red-600'>{errorRegister.password[0]}</span>
							) : null
						) : null}
					</div>

					<div className='flex flex-col'>
						<label className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Kota
						</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: errorRegister && errorRegister.cityID ? '#e53e3e' : '#a0aec0',
								}),
							}}
							isClearable
							isLoading={cities.isLoading}
							options={cities.data}
							onChange={val => setRegisterForm({ ...registerForm, cityID: val ? val.value : '' })}
						/>
						{errorRegister ? (
							errorRegister.cityID ? (
								<span className='text-xs text-red-600'>{errorRegister.cityID[0]}</span>
							) : null
						) : null}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='userPoheRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Telepon
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.phone ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userPoheRegister'
								type='number'
								placeholder='082138123'
								className='w-full h-full focus:outline-none'
								value={registerForm.phone}
								onChange={e => setRegisterForm({ ...registerForm, phone: e.target.value })}
							/>
						</div>
						{errorRegister ? (
							errorRegister.phone ? (
								<span className='text-xs text-red-600'>{errorRegister.phone[0]}</span>
							) : null
						) : null}
						<small className='text-xs text-gray-600 ml-1 mt-2'>
							Perusahaan memerlukan informasi ini untuk menghubungimu jika cocok.
						</small>
					</div>
				</div>

				<div className='flex flex-col'>
					<label className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Pilih Poin Alamat
					</label>

					<AsyncSelect
						styles={{
							control: (provided, state) => ({
								...provided,
								height: '43px',
								borderColor: '#a0aec0',
							}),
						}}
						cacheOptions
						onChange={options => {
							if (options) {
								let parsed = JSON.parse(options.value)
								setPosition(parsed)
								setRegisterForm({ ...registerForm, latitude: parsed.lat, longitude: parsed.lng })
							} else {
								setPosition({ lat: -6.1753871, lng: 106.8249641 })
								setRegisterForm({ ...registerForm, latitude: -6.1753871, longitude: 106.8249641 })
							}
						}}
						// defaultOptions={addresArray}
						loadOptions={_.throttle(input => {
							return new Promise(resolve => {
								if (input.length === 0) {
									resolve([])
								} else {
									Geocode.fromAddress(input).then(geo => {
										resolve(
											geo.results.map(loc => ({
												value: JSON.stringify(loc.geometry.location),
												label: loc.formatted_address,
											}))
										)
									})
								}
							})
						}, 500)}
					/>

					<small className='text-xs text-gray-600 ml-1 mt-2'>
						Perusahaan memerlukan informasi ini agar bisa menemukanmu dengan mudah.
					</small>
				</div>

				<LocationPicker
					zoom={15}
					containerElement={<div style={{ height: '100%' }}></div>}
					mapElement={<div className='h-64 rounded-md mt-4' />}
					defaultPosition={position}
					onChange={e => {
						setPosition({ lat: e.position.lat, lng: e.position.lng })
						getAddressByLatLng(e.position.lat, e.position.lng)
					}}
				/>

				<div className='flex mt-6 items-center'>
					<input
						type='checkbox'
						name='aggreeee'
						id='aggreeee'
						value={isAgree}
						onChange={e => setIsAgree(e.target.checked)}
					/>
					<label htmlFor='aggreeee' className='text-xs ml-4 text-gray-700'>
						Saya menyetujui syarat dan ketentuan
					</label>
				</div>

				{is401 && (
					<div className='bg-red-400 py-2 px-4 rounded-md text-sm my-4 text-white flex justify-between'>
						<span>
							{' '}
							<strong>Gagal daftar!</strong> Anda harus memasukan password lama anda
						</span>

						<span className='cursor-pointer' onClick={() => setIs401(false)}>
							X
						</span>
					</div>
				)}

				<div className='flex justify-center'>
					{submitting ? (
						<button className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black cursor-not-allowed opacity-50'>
							Daftar <i className='icofont-spinner-alt-1'></i>
						</button>
					) : isAgree ? (
						<button
							onClick={onRegister}
							className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black'>
							Daftar
						</button>
					) : (
						<button className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black cursor-not-allowed opacity-50'>
							Daftar
						</button>
					)}
				</div>
				<hr />
				<p className='text-sm mt-4 text-gray-700 text-center'>
					Sudah punya akun ?{' '}
					<span className='text-indigo-500 cursor-pointer' onClick={() => setModalLoginVisible(true)}>
						Masuk
					</span>{' '}
				</p>

				<p className='text-xs mt-4 text-gray-700 text-center'>
					Anda perusahaan ?{' '}
					<Link to='register-company'>
						<span className='text-indigo-500 cursor-pointer'>Klik disini</span>{' '}
					</Link>
				</p>
			</div>
			<br />

			<LoginModal
				visible={modalLoginVisible}
				onCancel={() => setModalLoginVisible(false)}
				onLoginCompany={() => {
					setModalLoginVisibleCompany(true)
					setModalLoginVisible(false)
				}}
			/>
			<LoginModalCompany
				visible={modalLoginVisibleCompany}
				onCancel={() => setModalLoginVisibleCompany(false)}
				onLoginJobSeeker={() => {
					setModalLoginVisible(true)
					setModalLoginVisibleCompany(false)
				}}
			/>
		</div>
	)
}

export default Register
