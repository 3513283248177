import React from 'react'

const Input = ({
	label,
	type,
	icon,
	placeholder,
	onChange,
	className = '',
	value,
	name,
	showError = false,
	errorMsg = '',
}) => {
	return (
		<div className={'flex flex-col ' + className}>
			<label htmlFor={name} className='font-semibold mb-1'>
				{label}
			</label>
			<div className='w-full flex items-center border px-4 py-2 rounded-md text-gray-700'>
				<span>{icon}</span>
				<input
					id={name}
					value={value}
					type={type}
					placeholder={placeholder}
					className='w-full ml-4 h-full focus:outline-none'
					onChange={onChange}
				/>
			</div>
			{showError && <small className='text-xs mt-1 mr-1 text-red-700'>{errorMsg}</small>}
		</div>
	)
}

export default Input
