import React, { useContext } from 'react'
import Popper from 'popper.js'
import { DataContext } from '../context/DataContext'

const Dropdown = ({ color = 'white', onSelectSection }) => {
	const dataContext = useContext(DataContext)
	const { defaultJobFunction } = dataContext

	// dropdown props
	const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false)
	const btnDropdownRef = React.createRef()
	const popoverDropdownRef = React.createRef()
	const openDropdownPopover = e => {
		new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
			placement: 'bottom-start',
		})
		setDropdownPopoverShow(true)
		e.stopPropagation()
	}
	const closeDropdownPopover = e => {
		setDropdownPopoverShow(false)
		e.stopPropagation()
	}
	// bg colors
	let bgColor
	color === 'white' ? (bgColor = 'bg-gray-800') : (bgColor = 'bg-' + color + '-500')
	return (
		<>
			<div className='flex flex-wrap'>
				<div className='w-full px-4'>
					<div className='relative inline-flex align-middle w-full'>
						<span
							className='lg:p-4 py-3 px-0 flex items-center border-b-4 border-transparent transition duration-500 hover:border-indigo-400 cursor-pointer'
							ref={btnDropdownRef}
							onClick={e => {
								dropdownPopoverShow ? closeDropdownPopover(e) : openDropdownPopover(e)
							}}>
							<span>Fungsi Pekerjaan</span> <i className='icofont-thin-down ml-2'></i>
						</span>
						<div
							ref={popoverDropdownRef}
							className={
								(dropdownPopoverShow ? 'block ' : 'hidden ') +
								(color === 'white' ? 'bg-white ' : bgColor + ' ') +
								'text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 drop-down-wrapper'
							}
							style={{ width: '16rem' }}>
							<div className='flex px-2 items-center justify-end'>
								<i className='icofont-close-line cursor-pointer' onClick={e => closeDropdownPopover(e)}></i>
							</div>
							{/* <div className='flex px-3 items-center'>
								<i className='icofont-search-1'></i>
								<input
									type='text'
									name='Lorem'
									id='searchFunction'
									className='w-full focus:outline-none ml-2'
									placeholder='Filter..'
								/>
							</div> */}

							{/* <div className='h-0 my-2 border border-solid border-t-0 border-gray-900 opacity-25' /> */}

							{defaultJobFunction.map(jobFunc => (
								<div key={jobFunc.value}>
									<a
										href='#pablo'
										className={
											'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent ' +
											(color === 'white' ? ' text-gray-800' : 'text-white')
										}
										onClick={e => {
											e.preventDefault()
											onSelectSection(jobFunc.value)
											closeDropdownPopover(e)
										}}>
										{jobFunc.label}
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Dropdown
