import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import gravatar from 'gravatar'

const CompanyCard = ({ company }) => {
	const [errorAvatar, setErrorAvatar] = useState(false)

	return (
		<Link to={`/company/${company.ID}`}>
			<div className='flex rounded-md border p-2 relative bg-white w-full h-full'>
				<div>
					<img
						// src={
						// 	company.companyAvatar?.avatar ??
						// 	'https://cdn.pixabay.com/photo/2019/12/14/07/21/building-4694350_1280.png'
						// }
						onError={() => setErrorAvatar(true)}
						src={
							errorAvatar
								? gravatar.url(company.email, { s: 50, default: 'identicon' })
								: company.companyAvatar?.avatar ?? gravatar.url(company.email, { s: 50, default: 'identicon' })
						}
						alt='DOOgether'
						className='h-12 w-12 object-cover rounded-sm'
					/>
				</div>

				<div className='px-6'>
					<h1 className='text-sm font-bold'>{company.name}</h1>

					<div className='text-xs flex items-center'>
						<i className='icofont-location-pin text-gray-600'></i>
						<span className='pl-1'>{company.city?.name}</span>
					</div>

					<div className='text-xs flex items-center'>
						<i className='icofont-people text-gray-600'></i>
						<span className='pl-1'>{company.companySize?.label}</span>
					</div>

					<div className='text-xs flex items-center'>
						<i className='icofont-location-arrow text-gray-600'></i>
						<span className='pl-1'> {company?.distance ?? '-'} Km</span>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default CompanyCard
