import React from 'react'
import { Link } from 'react-router-dom'

const Cards = () => {
	return (
		<div className='container mx-auto h-full -mt-32'>
			<div className='flex mb-4 flex-col lg:flex-row'>
				<div className='w-full px-4 lg:w-1/2 lg:pl-48 lg:pr-2'>
					<div className='bg-white w-full h-64 p-4 rounded-md shadow-md flex flex-col justify-between'>
						<h1 className='font-bold antialiased text-lg'>MENCARI LOWONGAN PEKERJAAN ATAU MAGANG?</h1>
						<div className='h-1 mt-2 w-1/3 bg-purple-400'></div>
						<p className='mt-2'>
							Temukan lowongan kerja & magang dari perusahaan terbaik dan gapai karir yang kamu suka!
						</p>

						<Link to='/job'>
							<button className='bg-yellow-400 p-2 w-full mt-auto rounded-md shadow-md hover:shadow-none focus:outline-none hover:bg-orange-300 transition duration-200 ease-in-out'>
								<i className='icofont-search-job'></i> Temukan Lowongan Pekerjaan
							</button>
						</Link>
					</div>
				</div>

				<div className='w-full px-4 mt-3 lg:mt-0 lg:w-1/2 lg:pr-48 lg:pl-2'>
					<div className='bg-white w-full h-64 p-4 rounded-md shadow-md flex flex-col justify-between'>
						<h1 className='font-bold antialiased text-lg'>MENCARI KANDIDAT TERBAIK?</h1>
						<div className='h-1 mt-2 w-1/3 bg-purple-400'></div>
						<p className='mt-2'>Iklan lowongan pekerjaan gratis. Temukan kandidat terbaik untuk menggapai misi-misi mu!</p>

						<Link to='/register-company'>
							<button className='bg-yellow-400 p-2 w-full mt-auto rounded-md shadow-md hover:shadow-none focus:outline-none hover:bg-orange-300 transition duration-200 ease-in-out'>
								<i className='icofont-ui-office'></i> Masuk Perusahaan
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cards
