import React, { useContext, useEffect, useState } from 'react'
import Dropdown from '../../components/DropDown'
import JobCard from '../../components/JobCard'
import LoadingState from '../../components/LoadingState'
import CompanyCard from '../../components/CompanyCard'
import { JobContext } from '../../context/JobContext'
import MasterAPI from '../../services/MasterAPI'
import queryString from 'query-string'
import JobSeekerCard from '../../components/JobSeekerCard'
import DropDownDistanceJobSeeker from '../../components/DropDownDistanceJobSeeker'

const ListAplicant = ({ history, location }) => {
	const jobContext = useContext(JobContext)
	const { jobs } = jobContext

	const [jobSeeker, setJobSeeker] = useState({ data: [], isLoading: false })
	const [parametersView, setParametersView] = useState({ pageSize: 20, keyword: '' })
	const [jobSeekerParams, setJobSeekerParams] = useState({})

	const getJobSeeker = async (params = {}) => {
		try {
			setJobSeeker({ ...jobSeeker, isLoading: true })
			const response = await MasterAPI.listJobSeeker({
				...params,
				pageSize: 20,
				order: 'desc',
				sort: 'distance',
			})
			setJobSeeker({
				isLoading: false,
				data: response.data.list,
			})
		} catch (error) {
			console.log(error)
		}
	}

	let parsedSearch = queryString.parse(location.search)

	useEffect(() => {
		if (location.search) {
			getJobSeeker({
				...parsedSearch,
				keyword: parsedSearch?.q ?? '',
				jobFunctionID: parsedSearch?.jobFunctionID ?? '',
				jobTypeID: parsedSearch?.jobTypeID ?? '',
				distanceStart: parsedSearch?.distanceStart ?? '',
				distanceEnd: parsedSearch?.distanceEnd ?? '',
			})
			setJobSeekerParams({
				...parsedSearch,
				q: parsedSearch?.q ?? '',
				jobFunctionID: parsedSearch?.jobFunctionID ?? '',
				jobTypeID: parsedSearch?.jobTypeID ?? '',
				distanceStart: parsedSearch?.distanceStart ?? '',
				distanceEnd: parsedSearch?.distanceEnd ?? '',
			})
			setParametersView({
				...parametersView,
				keyword: parsedSearch?.q ?? '',
				jobFunctionID: parsedSearch?.jobFunctionID ?? '',
				jobTypeID: parsedSearch?.jobTypeID ?? '',
				distanceStart: parsedSearch?.distanceStart ?? '',
				distanceEnd: parsedSearch?.distanceEnd ?? '',
			})
		} else {
			getJobSeeker({})
		}
	}, [location.search])

	const onChangeRoute = () => {
		if (Object.keys(jobSeekerParams).length > 0) {
			history.push(`/job-seeker?${queryString.stringify(jobSeekerParams)}`)
		}
	}

	useEffect(() => {
		onChangeRoute()
	}, [jobSeekerParams])

	return (
		<div className='container mx-auto '>
			<div className='lg:px-48 px-4 pb-16  mt-16'>
				<div className='flex items-center'>
					<i className='icofont-search-2 text-2xl'></i>
					<input
						value={parametersView.keyword}
						className='w-full ml-8 py-2 px-4 bg-transparent border-b-2 border-gray-600 focus:outline-none'
						placeholder='Cari pelamar...'
						onChange={e => {
							setParametersView({ ...parametersView, keyword: e.target.value })
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								setJobSeekerParams({ ...jobSeekerParams, q: e.currentTarget.value })
							}
						}}
					/>
				</div>
			</div>
			<div className='p-4 w-full'>
				<ul className='flex items-center justify-start text-base text-gray-700 pt-4 lg:pt-0'>
					<li>
						<DropDownDistanceJobSeeker
							onSelectSection={value => {
								getJobSeeker({ ...parsedSearch, ...value })
								setParametersView({ ...parametersView, ...value })
								setJobSeekerParams({ ...jobSeekerParams, ...value })
							}}
						/>
					</li>
					{/* <li>
						<Dropdown />
					</li>
					<li>
						<Dropdown />
					</li> */}
				</ul>

				<hr />
				<br />

				{location.search && (
					<div>
						<div className='flex flex-wrap w-full'>
							{parsedSearch.q && (
								<div className='px-4 py-2 bg-indigo-500 rounded-full flex items-center text-sm text-white ml-2 mt-2'>
									<span>Keyword: {parsedSearch.q}</span>
									<span
										className='text-xs ml-2 font-bold cursor-pointer'
										onClick={e => {
											e.preventDefault()
											if (parsedSearch.q) {
												delete parsedSearch.q
											}
											console.log('after', parsedSearch)
											// history.push({ ...history, location: { ...history.location, search: parsedSearch } })
											history.push({
												pathname: '/job-seeker',
												search: `?${queryString.stringify(parsedSearch)}`,
											})
											setParametersView({ ...parametersView, keyword: '' })
										}}>
										X
									</span>
								</div>
							)}

							{parsedSearch.distanceStart && parsedSearch.distanceEnd && (
								<div className='px-4 py-2 bg-indigo-500 rounded-full flex items-center text-sm text-white ml-2 mt-2'>
									<span>
										Jarak: {parsedSearch.distanceStart} - {parsedSearch.distanceEnd}
									</span>
									<span
										className='text-xs ml-2 font-bold cursor-pointer'
										onClick={e => {
											e.preventDefault()
											if (parsedSearch.distanceStart) {
												delete parsedSearch.distanceStart
											}

											if (parsedSearch.distanceEnd) {
												delete parsedSearch.distanceEnd
											}
											// history.push({ ...history, location: { ...history.location, search: parsedSearch } })
											history.push({
												pathname: '/job-seeker',
												search: `?${queryString.stringify(parsedSearch)}`,
											})
											setParametersView({ ...parametersView, distanceEnd: '', distanceStart: '' })
										}}>
										X
									</span>
								</div>
							)}
						</div>
						<br />

						<hr />
					</div>
				)}

				{jobSeeker.isLoading ? (
					<LoadingState />
				) : !jobSeeker.isLoading && jobSeeker.data.length === 0 ? (
					<div className='flex h-full py-16 w-full items-center justify-center'>
						<div>
							<img
								src={require('../../assets/images/undraw_not_found_60pq.png')}
								className={`object-contain rounded-full h-64 w-64`}
								alt='Empty'
							/>

							<h1 className='text-center text-lg font-bold mt-8'>Tidak menemukan pelamar :(</h1>
						</div>
					</div>
				) : (
					<div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-3'>
						{jobSeeker.data.map(seeker => (
							<JobSeekerCard key={seeker.ID} jobSeeker={seeker} />
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default ListAplicant
