import Axios from '../utils/axios'
import queryString from 'query-string'
import { reject } from 'lodash'

export default class JobAPI {
	static getJobs(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/job?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getJobFunc(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			const response = await Axios.get(`/mitra-vokasi/job/job-function?${queryString.stringify(parameters)}`)

			if (response.data.data.list) {
				const result = response.data.data.list.map(jobFunc => ({
					value: jobFunc.ID,
					label: jobFunc.label,
				}))
				resolve(result)
			}

			if (response.data.data.list.length === 0) {
				reject()
			}
		})
	}

	static getJobType(parameters = {}) {
		try {
			return new Promise(async (resolve, reject) => {
				const response = await Axios.get(`/mitra-vokasi/job/job-type?${queryString.stringify(parameters)}`)

				if (response.data.data.list) {
					const result = response.data.data.list.map(jobType => ({
						value: jobType.ID,
						label: jobType.label,
					}))
					resolve(result)
				}
			})
		} catch (error) {
			reject(error)
		}
	}

	static getJobDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/job/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getApplicant(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/job-applicant?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static applyJob(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/mitra-vokasi/job-applicant', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createJob(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/mitra-vokasi/job', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static editJob(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/job/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static changeStatusApplikan(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/job-applicant/${id}/status`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static checkIsApplied(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/mitra-vokasi/job-applicant/check`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static assignSkills(skills = [], removedSkills = [], id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				if (skills.length > 0) {
					skills.forEach(skill => {
						formData.append('creates[]', skill.value)
					})
				}

				if (removedSkills.length > 0) {
					removedSkills.forEach(skill => {
						formData.append('removes[]', skill)
					})
				}

				const response = await Axios.post(`/mitra-vokasi/job/assign-skill/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
