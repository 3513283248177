import React, { Fragment, useContext, useState, useRef, useEffect } from 'react'
import CertificateCard from '../../components/CertificateCard'
import EducationCard from '../../components/EducationCard'
import JobCard from '../../components/JobCard'
import LoadingState from '../../components/LoadingState'
import WorkHistoryCard from '../../components/WorkHistoryCard'
import { AuthContext } from '../../context/AuthContext'
import EditProfileModal from './components/EditProfileModal'
import EducationModal from './components/EducationModal'
import JobModal from './components/JobModal'
import SkillsModal from './components/SkillsModal'
import gravatar from 'gravatar'
import MasterAPI from '../../services/MasterAPI'
import { toast } from 'react-toastify'
import CertificateModal from './components/CertificateModal'
import PasswordModal from './components/PasswordModal'

const Profile = () => {
	const authContext = useContext(AuthContext)
	const [modalEditVisible, setModalEditVisible] = useState(false)
	const [modalEducationVisible, setModalEducationVisible] = useState(false)
	const [modalEditEducationVisible, setModalEducationEditVisible] = useState(false)
	const [educationEdit, setEducationEdit] = useState(null)
	const [certificateEdit, setCertificateEdit] = useState(null)
	const [modalSkillVisible, setModalSkillVisible] = useState(false)
	const [jobModalVisible, setJobModalVisible] = useState(false)
	const [jobEditVisible, setJobEditVisible] = useState(false)
	const [jobEdit, setJobEdit] = useState(null)
	const [uploading, setUploading] = useState(false)
	const [avatarError, setAvatarError] = useState(false)
	const [coverError, setCoverError] = useState(false)
	const [passwordModalVisible, setPasswordModalVisible] = useState(false)
	const [certificateModalVisible, setCertificateModalVisible] = useState(false)
	const [certificateModalVisibleEdit, setCertificateModalVisibleEdit] = useState(false)

	const avatarUpload = useRef(null)
	const coverUpload = useRef(null)

	const { userProfile, userProfileEdit, getUserProfile } = authContext

	return (
		<div className='container mx-auto'>
			{userProfile.isLoading && userProfileEdit.isLoading ? (
				<LoadingState />
			) : (
				<Fragment>
					<div className='mt-12 flex flex-col md:flex-row'>
						<div className='w-full flex-1 lg:mr-2 px-4 md:px-0'>
							<div className='bg-white rounded-md border'>
								<div className='h-32 md:h-48 overflow-hidden object-center mx-auto relative'>
									<img
										onError={() => setCoverError(true)}
										src={
											coverError
												? 'https://picsum.photos/920/192'
												: userProfile.data.jobSeekerAvatar?.cover ?? 'https://picsum.photos/920/192'
										}
										alt='Jiso'
										className='w-full h-full object-cover rounded-t-md'
									/>
									<input
										disabled={uploading}
										ref={coverUpload}
										type='file'
										accept='image/png,image/jpg,image/jpeg'
										name='coverUpload'
										className='hidden'
										id='coverUpload'
										onChange={e => {
											if (!uploading) {
												setUploading(true)
												MasterAPI.updateJobSeekerAvatar({ cover: e.target.files[0] })
													.then(() => {
														getUserProfile()
														toast.success('Berhasil ubah cover')
														setUploading(false)
													})
													.catch(() => {
														toast.error('Gagal upload cover')
													})
											}
										}}
									/>

									<span className='absolute right-0 top-0 p-2'>
										<button
											onClick={() => coverUpload.current.click()}
											className='bg-white border text-gray-600 font-medium py-1 px-2 rounded-full text-xs focus:outline-none'
											type='button'>
											<i className='icofont-ui-edit text-xs'></i>
										</button>
									</span>
								</div>

								<div className='px-6 text-gray-800 pb-8 relative'>
									<div className='-mt-20 flex justify-between items-center px-4'>
										<div className='mt-8 flex-1 flex'>
											{/* <button className='bg-indigo-800 text-white font-medium px-4 py-2 rounded-md text-sm'>CV</button> */}
										</div>

										<div className='flex-1 relative'>
											<div className='bg-black h-32 w-32 md:h-48 md:w-48 rounded-full user-avatar-container relative transition duration-200 mx-auto'>
												<img
													onError={() => setAvatarError(true)}
													className='h-32 w-32 md:h-48 md:w-48 object-cover rounded-full border p-1 bg-white mx-auto user-avatar'
													src={
														avatarError
															? gravatar.url(userProfile.data.email, { s: 200, default: 'identicon' })
															: userProfile.data.jobSeekerAvatar?.avatar ??
															  gravatar.url(userProfile.data.email, { s: 200, default: 'identicon' })
													}
													alt='Profile'
												/>
												<div className='absolute transform -translate-x-1/2 -translate-y-1/2 button-avatar'>
													<button
														className='bg-gray-100 rounded-none py-2 px-3 text-sm focus:outline-none'
														onClick={() => {
															avatarUpload.current.click()
														}}>
														Ubah avatar
													</button>
												</div>
											</div>
										</div>

										<input
											disabled={uploading}
											ref={avatarUpload}
											type='file'
											accept='image/png,image/jpg,image/jpeg'
											name='avatarUpload'
											className='hidden'
											id='avatarUpload'
											onChange={e => {
												if (!uploading) {
													setUploading(true)
													MasterAPI.updateJobSeekerAvatar({ avatar: e.target.files[0] })
														.then(() => {
															getUserProfile()
															toast.success('Berhasil ubah avatar')
															setUploading(false)
														})
														.catch(() => {
															toast.error('Gagal upload avatar')
														})
												}
											}}
										/>

										<div className='mt-8 flex-1 flex justify-end'>
											{/* <button className='bg-indigo-800 text-white font-medium rounded-md text-sm'></button> */}
											<button
												onClick={() => setModalEditVisible(true)}
												className='bg-white border text-gray-600 font-medium py-1 px-2 rounded-full text-xs focus:outline-none'>
												<i className='icofont-ui-edit'></i>
											</button>
										</div>
									</div>

									<div className='text-center mt-2 mb-8'>
										<h1 className='text-xl font-bold'>{userProfile.data.name}</h1>

										<h2>{userProfile.data.city ? userProfile.data.city.name : ''}</h2>
										{userProfile.data.currentEducation && (
											<Fragment>
												<h2 className='font-medium mt-4'>
													{userProfile.data.currentEducation.jobSeekerEducationDetail?.fieldStudy ?? ''}
												</h2>
												<h1 className='font-light'>{userProfile.data.currentEducation.schoolName}</h1>
											</Fragment>
										)}
									</div>

									{userProfile.data.about && (
										<Fragment>
											<hr />
											<div className='text-center mt-8 lg:px-16'>
												<h1 className='text-xl font-bold mb-2 italic'>Tentang</h1>
												<p className='font-light text-base'>
													<i className='icofont-quote-left'></i> &nbsp; {userProfile.data.about}
												</p>
											</div>
										</Fragment>
									)}
								</div>
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Skills</h1>
									<span>
										<i
											className='icofont-ui-edit text-sm cursor-pointer'
											onClick={() => setModalSkillVisible(true)}></i>
									</span>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								{userProfile.data.skills.length > 0 ? (
									<div className='w-full flex flex-wrap'>
										{userProfile.data.skills.map((skill, index) => (
											<span className='text-xs px-3 py-1 bg-gray-300 rounded-full m-1'>
												{skill.jobSeekerSkill.label}
											</span>
										))}
									</div>
								) : (
									<div className='w-full'>
										<div className='mx-auto text-center'>
											<img
												src={require('../../assets/images/empty.png')}
												className='h-32 mx-auto'
												alt='Empty Education'
											/>
											<p>Belum ada data...</p>
										</div>
									</div>
								)}
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Pengalaman Kerja</h1>
									<span>
										<i
											className='icofont-plus cursor-pointer'
											onClick={() => {
												setJobModalVisible(true)
											}}></i>
									</span>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								{userProfile.data.jobSeekerExperiences.length > 0 ? (
									<div className='grid gap-4 md:grid-cols-2'>
										{userProfile.data.jobSeekerExperiences.map(exp => (
											<WorkHistoryCard
												experience={exp}
												showEdit={true}
												onEdit={() => {
													setJobEdit(exp)
													setJobEditVisible(true)
												}}
											/>
										))}
									</div>
								) : (
									<div className='w-full'>
										<div className='mx-auto text-center'>
											<img
												src={require('../../assets/images/empty.png')}
												className='h-32 mx-auto'
												alt='Empty Education'
											/>
											<p>Belum ada data...</p>
										</div>
									</div>
								)}
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Pendidikan</h1>
									<button
										className='border-none bg-transparent focus:outline-none'
										onClick={() => setModalEducationVisible(true)}>
										<i className='icofont-plus'></i>
									</button>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>

								{userProfile.data.jobSeekerEducations.length > 0 ? (
									<div className='grid gap-4 md:grid-cols-2'>
										{userProfile.data.jobSeekerEducations.map(education => (
											<EducationCard
												key={education.ID}
												education={education}
												onEdit={() => {
													setModalEducationEditVisible(true)
													setEducationEdit({
														...education,
														fieldStudy: education.jobSeekerEducationDetail.fieldStudy,
														startYear: education.jobSeekerEducationDetail.startYear,
														startMonth: education.jobSeekerEducationDetail.startMonth,
														endYear: education.jobSeekerEducationDetail.endYear,
														endMonth: education.jobSeekerEducationDetail.endMonth,
													})
												}}
											/>
										))}
									</div>
								) : (
									<div className='w-full'>
										<div className='mx-auto text-center'>
											<img
												src={require('../../assets/images/empty.png')}
												className='h-32 mx-auto'
												alt='Empty Education'
											/>
											<p>Belum ada data...</p>
										</div>
									</div>
								)}
							</div>

							<div className='bg-white border rounded-md p-6 mt-4'>
								<div className='flex justify-between'>
									<h1 className='font-bold text-xl'>Lisensi dan Sertifikasi</h1>
									<button
										className='border-none bg-transparent focus:outline-none'
										onClick={() => setCertificateModalVisible(true)}>
										<i className='icofont-plus'></i>
									</button>
								</div>
								<div className='w-20 h-1 bg-indigo-500 mt-4 mb-6'></div>
								{userProfile.data.jobSeekerLicenseCertifications.length > 0 ? (
									<div className='grid gap-4 md:grid-cols-2'>
										{userProfile.data.jobSeekerLicenseCertifications.map((cert, index) => (
											<CertificateCard
												key={index}
												certificate={cert}
												showEdit
												onEdit={() => {
													setCertificateEdit(cert)
													setCertificateModalVisibleEdit(true)
												}}
											/>
										))}
									</div>
								) : (
									<div className='w-full'>
										<div className='mx-auto text-center'>
											<img
												src={require('../../assets/images/empty.png')}
												className='h-32 mx-auto'
												alt='Empty Education'
											/>
											<p>Belum ada data...</p>
										</div>
									</div>
								)}
							</div>
						</div>

						{/* Right side */}
						<div className='bg-transparent w-full md:overflow-auto md:w-1/4 md:ml-2 min-h-0 text-gray-800 pb-8 px-4 mt-4 md:px-0 md:mt-0'>
							<div className='bg-white p-4 border rounded-md'>
								<h1 className='text-base font-bold mb-4'>Lainnya</h1>
								<div className='w-20 h-px bg-indigo-500 mt-2 mb-4'></div>
								<div className='grid gap-3 grid-cols-2'>
									<button
										className='py-2 rounded-sm bg-gray-300 text-xs focus:outline-none'
										onClick={() => setPasswordModalVisible(true)}>
										Ubah Password
									</button>
									<button
										className='focus:outline-none'
										onClick={() => {
											localStorage.clear()
											window.location.href = '/'
										}}>
										Keluar <i className='icofont-sign-out'></i>
									</button>
								</div>
							</div>
						</div>
					</div>

					<br />
					<br />
					<EditProfileModal visible={modalEditVisible} onCancel={() => setModalEditVisible(false)} />
					<EducationModal mode='add' visible={modalEducationVisible} onCancel={() => setModalEducationVisible(false)} />
					<EducationModal
						mode='edit'
						visible={modalEditEducationVisible}
						onCancel={() => {
							setModalEducationEditVisible(false)
							setEducationEdit(null)
						}}
						educationEdit={educationEdit}
						setEducationEdit={setEducationEdit}
					/>
					<SkillsModal
						visible={modalSkillVisible}
						onCancel={() => {
							setModalSkillVisible(false)
							// setEducationEdit(null)
						}}
						currentSkills={userProfile.data.skills}
					/>

					<JobModal
						visible={jobModalVisible}
						onCancel={() => {
							setJobModalVisible(false)
						}}
					/>

					<JobModal
						jobEdit={jobEdit}
						setJobEdit={setJobEdit}
						mode='edit'
						visible={jobEditVisible}
						onCancel={() => {
							setJobEditVisible(false)
							setJobEdit(null)
						}}
					/>

					<CertificateModal
						visible={certificateModalVisible}
						onCancel={() => {
							setCertificateModalVisible(false)
						}}
					/>
					<CertificateModal
						mode='edit'
						educationEdit={certificateEdit}
						visible={certificateModalVisibleEdit}
						onCancel={() => {
							setCertificateModalVisibleEdit(false)
							setCertificateEdit(null)
						}}
						setEducationEdit={setCertificateEdit}
					/>

					<PasswordModal
						visible={passwordModalVisible}
						onCancel={() => setPasswordModalVisible(false)}
						onSuccess={() => getUserProfile()}
					/>
				</Fragment>
			)}
		</div>
	)
}

export default Profile
