import React, { Fragment } from 'react'
import Header from './components/Header.js'
import Home from './Pages/Home'
import Job from './Pages/Job'
import Register from './Pages/Auth/Register.js'
import RegisterCheck from './Pages/Auth/RegisterCheck'
import Profile from './Pages/Profile'
import JobDetail from './Pages/Job/Detail'
import RegisterCompany from './Pages/Auth/RegisterCompany'
import ProfileCompany from './Pages/Company/Profile'
import DetailCompany from './Pages/Company/Detail'
import Company from './Pages/Company/index.js'
import ApplicantDetail from './Pages/Applicant/Detail'
import ListAplicant from './Pages/Applicant'

import { ToastContainer } from 'react-toastify'

import { Route, BrowserRouter as Router } from 'react-router-dom'
import RegisterCompanyCheck from './Pages/Auth/RegisterCompanyCheck.js'
const TOKEN = localStorage.getItem('@user_token')

function App() {
	return (
		<Router>
			<Header />
			<ToastContainer />
			{TOKEN ? (
				<Fragment>
					<Route exact path='/' component={Home} />
					<Route exact path='/job' component={Job} />
					<Route exact path='/job/:id' component={JobDetail} />
					<Route exact path='/company/:id' component={DetailCompany} />
					<Route exact path='/company' component={Company} />
					<Route exact path='/applicant/:id' component={ApplicantDetail} />
					<Route exact path='/profile' component={Profile} />
					<Route exact path='/profile-company' component={ProfileCompany} />
					<Route exact path='/job-seeker' component={ListAplicant} />
				</Fragment>
			) : (
				<Fragment>
					<Route exact path='/job' component={Job} />
					<Route exact path='/job/:id' component={JobDetail} />
					<Route exact path='/company/:id' component={DetailCompany} />
					<Route exact path='/company' component={Company} />
					<Route exact path='/' component={Home} />
					<Route exact path='/register' component={Register} />
					<Route exact path='/register/check' component={RegisterCheck} />
					<Route exact path='/register-company' component={RegisterCompany} />
					<Route exact path='/register-company/check' component={RegisterCompanyCheck} />
				</Fragment>
			)}
		</Router>
	)
}

export default App
