import React, { createContext, useState, useEffect } from 'react'
import JobAPI from '../services/JobAPI'
import MasterAPI from '../services/MasterAPI'

export const DataContext = createContext()
export const DataContextConsumer = DataContext.Consumer

const DataContextProvider = ({ children }) => {
	const [cities, setCities] = useState({ data: [], isLoading: true })
	const [educationTier, setEducationTier] = useState({ data: [], isLoading: true })
	const [companySize, setCompanySize] = useState({ data: [], isLoading: true })
	const [defaultJobFunction, setDefaultJobFunction] = useState([])
	const [jobType, setJobType] = useState([])
	const [skills, setSkills] = useState([])

	const getCities = async () => {
		try {
			const response = await MasterAPI.getCity()
			setCities({
				isLoading: false,
				data: response.data.list.map(city => ({
					value: city.ID,
					label: city.name,
				})),
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchEducation = async () => {
		try {
			const response = await MasterAPI.getEducationTier()
			setEducationTier({
				data: response.data.list
					.filter(edu => ![1, 2, 3, 7].includes(edu.ID))
					.map(edu => ({
						value: edu.ID,
						label: edu.name,
					})),
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const loadDefaultJobFunction = () => {
		JobAPI.getJobFunc().then(result => setDefaultJobFunction(result))
	}

	const loadSkill = () => {
		MasterAPI.getSkills().then(result => setSkills(result))
	}

	const loadJobType = () => {
		JobAPI.getJobType().then(result => setJobType(result))
	}

	const fetchCompanySize = async () => {
		try {
			const response = await MasterAPI.listCompanySize()
			setCompanySize({
				data: response.data.list.map(size => ({
					value: size.ID,
					label: size.label,
				})),
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getCities()
		fetchEducation()
		fetchCompanySize()
		loadDefaultJobFunction()
		loadJobType()
		loadSkill()
	}, [])

	return (
		<DataContext.Provider
			value={{
				cities,
				setCities,
				educationTier,
				setEducationTier,
				companySize,
				setCompanySize,
				defaultJobFunction,
				setDefaultJobFunction,
				jobType,
				setJobType,
				skills,
				setSkills,
			}}>
			{children}
		</DataContext.Provider>
	)
}

export default DataContextProvider
