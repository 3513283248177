import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import LoginModal from '../../components/LoginModal'
import LoginModalCompany from '../../components/LoginModalCompany'
import { DataContext } from '../../context/DataContext'
import _ from 'lodash'
import { AuthContext } from '../../context/AuthContext'
import LocationPicker from 'react-location-picker'
import Geocode from 'react-geocode'
import MasterAPI from '../../services/MasterAPI'
import { Redirect } from 'react-router-dom'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API)

const RegisterCompany = () => {
	const dataContext = useContext(DataContext)
	const authContext = useContext(AuthContext)

	const { cities, companySize } = dataContext
	const { registerForm, setRegisterForm, errorRegister, is401, setIs401, hasValidMOU, validMOUData } = authContext

	const [modalLoginVisible, setModalLoginVisible] = useState(false)
	const [modalLoginVisibleCompany, setModalLoginVisibleCompany] = useState(false)
	const [isAgree, setIsAgree] = useState(false)
	const [position, setPosition] = useState({ lat: -6.1753871, lng: 106.8249641 })
	const [addresArray, setAddresArray] = useState([])
	const [isSameAddres, setIsSameAddress] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const [formValue, setFormValue] = useState({
		email: '',
		password: '',
		confirmedPassword: '',
		name: '',
		phone: '',
		latitude: 0,
		longitude: 0,
		address: '',
		description: '',
		headQuarter: '',
		founded: '',
		companySizeID: '',
		cityID: '',
	})

	const getAddressByLatLng = async (lat, lng) => {
		try {
			const geo = await Geocode.fromLatLng(lat, lng)
			setPosition(geo.results[0].geometry.location)
			setAddresArray(
				geo.results.map(loc => ({
					value: JSON.stringify(loc.geometry.location),
					label: loc.formatted_address,
				}))
			)
		} catch (error) {
			console.log(error)
		}
	}

	const onRegister = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				const response = await MasterAPI.registerCompany({
					...formValue,
					latitude: position.lat,
					longitude: position.lng,
				})

				if (response.data.token) {
					setSubmitting(false)
					localStorage.setItem('@user_token', response.data.token)
					localStorage.setItem('@role', 'Company')
					window.location.href = '/profile-company'
				}
			} catch (error) {
				console.log(error)
				if (error.response.status === 401) {
					setIs401(true)
				}
				setSubmitting(false)
			}
		}
	}

	useEffect(() => {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				position => {
					setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
					getAddressByLatLng(position.coords.latitude, position.coords.longitude)
				},
				error => {
					setPosition({ lat: -6.1753871, lng: 106.8249641 })
					getAddressByLatLng(-6.1753871, 106.8249641)
				}
			)
		} else {
			console.log('Not Available')
			setPosition({ lat: -6.1753871, lng: 106.8249641 })
			getAddressByLatLng(-6.1753871, 106.8249641)
		}
	}, [])

	useEffect(() => {
		if (hasValidMOU && validMOUData) {
			setFormValue({
				...formValue,
				name: validMOUData.name,
				phone: validMOUData.phone,
				cityID: validMOUData.cityID,
				founded: validMOUData.founded,
				address: validMOUData.address,
				headQuarter: validMOUData.headQuarter,
			})
		}
	}, [hasValidMOU, validMOUData])

	if (!hasValidMOU) {
		return <Redirect to='/register-company/check' />
	}

	return (
		<div className='container mx-auto'>
			<div className='text-center mt-16'>
				<h1 className='font-bold text-4xl'>Silahkan Buat</h1>
				<h1 className='font-bold text-4xl'>Profil Perusahaan Anda</h1>
			</div>
			<div className='register-container mx-auto mt-16 bg-white py-6 px-12 rounded-md shadow-md'>
				<div className=' md:grid flex flex-col md:grid-cols-2 gap-6'>
					{/* {input start} */}
					<div className='flex flex-col md:col-span-2'>
						<label htmlFor='nameRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Nama Perusahaan
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.name ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='nameRegister'
								type='text'
								placeholder='Tony Stark'
								className='w-full h-full focus:outline-none'
								value={formValue.name}
								onChange={e => setFormValue({ ...formValue, name: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.name ? (
								<span className='text-xs text-red-600'>{errorRegister.name[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col'>
						<label className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Kota
						</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: errorRegister && errorRegister.birthPlace ? '#e53e3e' : '#a0aec0',
								}),
							}}
							isClearable
							isLoading={cities.isLoading}
							options={cities.data}
							onChange={val => setFormValue({ ...formValue, cityID: val ? val.value : '' })}
						/>
						{/* {errorRegister ? (
							errorRegister.birthPlace ? (
								<span className='text-xs text-red-600'>{errorRegister.birthPlace[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='foundedRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tanggal Berdiri
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.birthDate ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='foundedRegister'
								type='date'
								className='w-full h-full focus:outline-none'
								value={formValue.founded}
								onChange={e => setFormValue({ ...formValue, founded: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.birthDate ? (
								<span className='text-xs text-red-600'>{errorRegister.birthDate[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col md:col-span-2'>
						<label htmlFor='userEmailregister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Email
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.email ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userEmailregister'
								type='email'
								placeholder='user@gmail.com'
								className='w-full h-full focus:outline-none'
								value={formValue.email}
								onChange={e => setFormValue({ ...formValue, email: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.email ? (
								<span className='text-xs text-red-600'>{errorRegister.email[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='userPasswordRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Password
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.password ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userPasswordRegister'
								type='password'
								placeholder='Password'
								className='w-full h-full focus:outline-none'
								value={formValue.password}
								onChange={e => setFormValue({ ...formValue, password: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.password ? (
								<span className='text-xs text-red-600'>{errorRegister.password[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col'>
						<label htmlFor='userPasswordRegister1' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Konfirmasi Password
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.password ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userPasswordRegister1'
								type='password'
								placeholder='Password'
								className='w-full h-full focus:outline-none'
								value={formValue.confirmedPassword}
								onChange={e => setFormValue({ ...formValue, confirmedPassword: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.password ? (
								<span className='text-xs text-red-600'>{errorRegister.password[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col col-span-2'>
						<label htmlFor='userPoheRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Telepon
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.phone ? 'border-red-600' : 'border-gray-500')
							}>
							<input
								id='userPoheRegister'
								type='number'
								placeholder='082138123'
								className='w-full h-full focus:outline-none'
								value={formValue.phone}
								onChange={e => setFormValue({ ...formValue, phone: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.phone ? (
								<span className='text-xs text-red-600'>{errorRegister.phone[0]}</span>
							) : null
						) : null} */}
						{/* <small className='text-xs text-gray-600 ml-1 mt-2'>
							Perusahaan memerlukan informasi ini untuk menghubungimu jika cocok.
						</small> */}
					</div>

					<div className='flex flex-col col-span-2'>
						<label className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Total Karyawan
						</label>
						<Select
							styles={{
								control: (provided, state) => ({
									...provided,
									height: '43px',
									borderColor: errorRegister && errorRegister.birthPlace ? '#e53e3e' : '#a0aec0',
								}),
							}}
							isClearable
							options={companySize.data}
							onChange={val => setFormValue({ ...formValue, companySizeID: val ? val.value : '' })}
						/>
						{/* {errorRegister ? (
							errorRegister.birthPlace ? (
								<span className='text-xs text-red-600'>{errorRegister.birthPlace[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col md:col-span-2'>
						<label htmlFor='nameRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Tentang Perusahaan
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.name ? 'border-red-600' : 'border-gray-500')
							}>
							<textarea
								id='nameRegister'
								placeholder='The company is ...'
								className='w-full h-full focus:outline-none'
								value={formValue.description}
								onChange={e => setFormValue({ ...formValue, description: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.name ? (
								<span className='text-xs text-red-600'>{errorRegister.name[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col md:col-span-2'>
						<label htmlFor='nameRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Alamat
						</label>
						<div
							className={
								'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
								(errorRegister && errorRegister.name ? 'border-red-600' : 'border-gray-500')
							}>
							<textarea
								id='nameRegister'
								placeholder='JL. Margonda'
								className='w-full h-full focus:outline-none'
								value={formValue.address}
								onChange={e => setFormValue({ ...formValue, address: e.target.value })}
							/>
						</div>
						{/* {errorRegister ? (
							errorRegister.name ? (
								<span className='text-xs text-red-600'>{errorRegister.name[0]}</span>
							) : null
						) : null} */}
					</div>

					<div className='flex flex-col md:col-span-2'>
						<label htmlFor='nameRegister' className='font-semibold mb-1'>
							<span className='text-xs text-red-600'>*</span> Alamat Kantor Pusat
						</label>
						<div className='flex mb-2 items-center ml-1'>
							<input
								type='checkbox'
								name='isSame'
								id='isSame'
								value={isSameAddres}
								onChange={e => {
									setIsSameAddress(e.target.checked)
									if (e.target.checked) {
										setFormValue({ ...formValue, headQuarter: formValue.address })
									} else {
										setFormValue({ ...formValue, headQuarter: '' })
									}
								}}
							/>
							<label htmlFor='isSame' className='text-xs ml-4 text-gray-700'>
								Alamat Kantor Pusat sama dengan alamat di atas
							</label>
						</div>

						{!isSameAddres && (
							<div
								className={
									'w-full flex items-center border px-4 py-2 rounded-md text-gray-700 ' +
									(errorRegister && errorRegister.name ? 'border-red-600' : 'border-gray-500')
								}>
								<textarea
									id='nameRegister'
									placeholder='JL. Margonda'
									className='w-full h-full focus:outline-none'
									value={formValue.headQuarter}
									onChange={e => setFormValue({ ...formValue, headQuarter: e.target.value })}
								/>
							</div>
						)}

						{/* {errorRegister ? (
							errorRegister.name ? (
								<span className='text-xs text-red-600'>{errorRegister.name[0]}</span>
							) : null
						) : null} */}
					</div>
				</div>

				<div className='flex flex-col mt-4'>
					<label className='font-semibold mb-1'>
						<span className='text-xs text-red-600'>*</span> Pilih Point Alamat
					</label>
					<AsyncSelect
						styles={{
							control: (provided, state) => ({
								...provided,
								height: '43px',
								borderColor: '#a0aec0',
							}),
						}}
						cacheOptions
						onChange={options => {
							let parsed = JSON.parse(options.value)
							setPosition(parsed)
							setFormValue({ ...formValue, latitude: parsed.lat, longitude: parsed.lng })
						}}
						// defaultOptions={addresArray}
						loadOptions={_.throttle(
							input => {
								return new Promise(resolve => {
									if (input.length === 0) {
										resolve([])
									} else {
										Geocode.fromAddress(input).then(geo => {
											resolve(
												geo.results.map(loc => ({
													value: JSON.stringify(loc.geometry.location),
													label: loc.formatted_address,
												}))
											)
										})
									}
								})
							},

							500
						)}
					/>
					<small className='text-xs text-gray-600 ml-1 mt-2'>
						Pelamar memerlukan informasi ini agar bisa menemukan perusahaan anda dengan mudah.
					</small>
				</div>

				<LocationPicker
					zoom={15}
					containerElement={<div style={{ height: '100%' }}></div>}
					mapElement={<div className='h-64 rounded-md mt-4' />}
					defaultPosition={position}
					onChange={e => {
						setPosition({ lat: e.position.lat, lng: e.position.lng })
						getAddressByLatLng(e.position.lat, e.position.lng)
					}}
				/>

				<div className='flex mt-6 items-center'>
					<input
						type='checkbox'
						name='aggreeee'
						id='aggreeee'
						value={isAgree}
						onChange={e => setIsAgree(e.target.checked)}
					/>
					<label htmlFor='aggreeee' className='text-xs ml-4 text-gray-700'>
						Saya menyetujui syarat dan ketentuan
					</label>
				</div>

				{is401 && (
					<div className='bg-red-400 py-2 px-4 rounded-md text-sm my-4 text-white flex justify-between'>
						<span>
							{' '}
							<strong>Gagal daftar!</strong> Anda harus memasukan password lama anda
						</span>

						<span className='cursor-pointer' onClick={() => setIs401(false)}>
							X
						</span>
					</div>
				)}

				<div className='flex justify-center'>
					{submitting ? (
						<button className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black cursor-not-allowed opacity-50'>
							Daftar <i className='icofont-spinner-alt-1'></i>
						</button>
					) : isAgree ? (
						<button
							onClick={onRegister}
							className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black'>
							Daftar
						</button>
					) : (
						<button className='p-2 bg-yellow-500 font-semibold rounded-md w-full md:w-1/2 flex justify-center items-center my-6 shadow-md hover:shadow-none focus:shadow-none focus:outline-none text-black cursor-not-allowed opacity-50'>
							Daftar
						</button>
					)}
				</div>
				<hr />
				<p className='text-sm mt-4 text-gray-700 text-center'>
					Sudah punya akun ?{' '}
					<span className='text-indigo-500 cursor-pointer' onClick={() => setModalLoginVisibleCompany(true)}>
						Masuk
					</span>{' '}
				</p>
			</div>
			<br />

			<LoginModal
				visible={modalLoginVisible}
				onCancel={() => setModalLoginVisible(false)}
				onLoginCompany={() => {
					setModalLoginVisibleCompany(true)
					setModalLoginVisible(false)
				}}
			/>
			<LoginModalCompany
				visible={modalLoginVisibleCompany}
				onCancel={() => setModalLoginVisibleCompany(false)}
				onLoginJobSeeker={() => {
					setModalLoginVisible(true)
					setModalLoginVisibleCompany(false)
				}}
			/>
		</div>
	)
}

export default RegisterCompany
